<template>
  <div>
    <rb-options-sheet :actions="actions" v-model="optionsheet" class="block md:hidden" v-on:event="(val) => $emit('event', val)">
      <slot name="buttonsheet"></slot>
    </rb-options-sheet>
    <rb-options-menu :actions="actions" v-model="optionmenu" class="hidden md:block" v-on:event="(val) => $emit('event', val)">
        <template #button>
            <slot name="buttonmenu"></slot>
        </template>
    </rb-options-menu>
  </div>
</template>

<script>
import RBOptionsButtomSheetVue from '../form/buttomsheets/RBOptionsButtomSheet.vue';
import RBMenuOptionsVue from '../form/menu/RBMenuOptions.vue';

export default {
    props: {
        actions: {
            type: Array,
            default: function() {
                return [];
            }
        },
    },
    data: function() {
        return {
            optionsheet: false,
            optionmenu: false,
        }
    },
    components: {
        'rb-options-sheet': RBOptionsButtomSheetVue,
        'rb-options-menu': RBMenuOptionsVue
    }
}
</script>

<style>

</style>