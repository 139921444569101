<template>
  <v-footer class="w-full" :padless="true" color="#ffffff">
          <div class="w-full h-auto py-2 md:py-0 md:h-44"> 
          
          <div class="md:ml-32 md:mr-32 h-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 mt-5">
              <div class="col-start-1 col-end-1">
                <div class="ml-5 md:ml-7 mt-1 w-24 h-9 cursor-pointer" @click="refresh()" >
                  <v-img  max-height="150" width="250"  :src="imgLogotype">
                  </v-img>
                </div>
              </div>
              <div class="col-start-1 col-end-1 md:col-start-2 md:col-end-3 ">
                <div class="flex items-center">
                  <ul class="flex items-center">
                    <li>  
                      <h5  class="cursor-pointer block py-2 pr-6 pl-4 text-navbar-text">Política de privacidad</h5>
                    </li>
                    <li>  
                      <h5  class="cursor-pointer block py-2 pr-6 pl-4 text-navbar-text">Términos y condiciones</h5>
                    </li>  
                 
                  </ul>
                </div>
              </div>
            </div>
        
            <v-divider class="mt-4 mb-4"></v-divider>

            <div class="grid grid-cols-1 md:grid-cols-2 mt-5">
              <div class="col-start-1 col-end-1">
                <p class="text-navbar-text text-sm ml-6">Rebyu ® {{ new Date().getFullYear() }} Todos los derechos reservados</p>
              </div>
              <div class="col-start-1 col-end-1 md:col-start-2 md:col-end-3">
                <div class="ml-1 md:ml-28 -mt-1">
                <div class="sm:flex sm:justify-center md:flex md:justify-end">
                <v-btn
                  v-for="icon in icons"
                  :key="icon"
                  class="mx-4 bg-gray-100"
                  icon
                >
                  <v-icon size="24px">
                    {{ icon }}
                  </v-icon>
                </v-btn>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-footer>
</template>

<script>
export default {
    data: function () {
        return {
                icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-instagram',
                ],
        imgLogotype:require('@/assets/logotype/logo_fullcolor.png')
        }
    },
    methods: {
            refresh(){
                window.location.href = '/home';
            }
    }

}
</script>

<style>

</style>