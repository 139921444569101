import axios from 'axios';
import urlcontext from './axios.urlcontext';
import { handleSuccess, handleError } from './axios.interceptors'

let HCore = axios.create({baseURL: urlcontext.APP_CORE_URL});
let HRebyu = axios.create({baseURL: urlcontext.APP_REBYU_URL});
let HProducts = axios.create({baseURL: urlcontext.APP_PRODUCTS_URL});
let HClient = axios.create();

// INTERCEPTORES
HCore.interceptors.response.use(handleSuccess, handleError)
HRebyu.interceptors.response.use(handleSuccess, handleError)
HProducts.interceptors.response.use(handleSuccess, handleError)

export {
    HCore,
    HRebyu,
    HProducts,
    HClient
};