// import ApiCore from '../../../config/ApiCore'
import { HCore } from '../../../config/axios.context'
import { APP_CORE_ADDRESS_REGISTER, APP_CORE_ADDRESS_BY_USER, APP_CORE_ADDRESS_BY_ID, APP_CORE_ADDRESS_SAVE, APP_CORE_ADDRESS_UPDATE, APP_CORE_ADDRESS_STATUS } from '../../../utils/apiurl';

export default  {

    /**
     * Funcion que permite obtener los roles del sistema
     * @returns 
     */
    async registerAddress(data) {
        try {
            const url = APP_CORE_ADDRESS_REGISTER;
            return await HCore.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Guarda los datos de direccion del usuario
     * @param {*} data Datos de dirección
     * @returns 
     */
    async save(data) {
        try {
            const url = APP_CORE_ADDRESS_SAVE;
            return await HCore.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Actualiza los datos de dirección del usuario
     * @param {*} id ID de la diorección
     * @param {*} data Datos de la dirección
     * @returns 
     */
    async update(id, data) {
        try {
            const url = APP_CORE_ADDRESS_UPDATE + id;
            return await HCore.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Obtiene las direcciones del uisuario
     * @returns Lista de direcciones
     */
    async getByUser() {
        try {
            const url = APP_CORE_ADDRESS_BY_USER;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Obtiene las direcciones del uisuario
     * @returns Lista de direcciones
     */
    async getById(id) {
        try {
            const url = APP_CORE_ADDRESS_BY_ID + id;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Actualiza el status de la dirección
     * @param {*} id ID de la dirección
     * @param {*} data Datos de la dirección
     * @returns 
     */
    async status(id, data) {
        try {
            const url = APP_CORE_ADDRESS_STATUS + id;
            return await HCore.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

}