<template>
  <div id="app" style="height: 100%">
    <v-app style="height: 100%">
        <v-dialog
          v-if="showLogin"
          v-model="showLogin"
          persistent
          max-width="700px"
        >
          <v-login
            :timeToExpired="ToExpired"
            v-if="showLogin"
            v-on:close="showLogin = false"
            @success="fnsuccess($event)"
          />
        </v-dialog>
        <component v-bind:is="layout" style="height: 100%">
          <router-view />
        </component>
     
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  MinutosTokenRestante /*,ObtenerFechaExpiracion*/,
} from "@/utils/token/token.js";
import Login from "@/views/session/components/loginModal.vue";
//import _tokens /*{ generarToken, OnRecived }*/ from '@/libs/fcm.js';
const default_layout = "default";

export default {
  name: "AppVue",
  components: {
    "v-login": Login,
  },
  data() {
    return {
      funcionMinutosRestantes: null,
      vMinutosRestantes: null,
      showLogin: false,
      ToExpired: 0,
    };
  },
  created() {
    this.getToken();
    this.getLocal();
    this.getSecury();

    // _tokens.generarToken();
  },

  mounted() {
    // _tokens.OnRecived();
    this.MinutosRestantes();
    this.location();
   

    this.funcionMinutosRestantes = setInterval(
      () => this.MinutosRestantes(),
      5000
    );
  },
  computed: {
    layout: function() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    ...mapGetters({
      user: "geolocation/currentlocation",
    }),
  },

  methods: {
    ...mapActions({
      getToken: "auth/getToken",
      getLocal: "auth/getLocal",
      getSecury: "auth/getSecury",
      forceCloseSession: "auth/forceCloseSession",
      setCurrentLocation: "geolocation/setCurrentLocation",
    }),

    location: async function() {
        let poss = await this.getCurrentCoords();
        if(!poss) return;
        this.setCurrentLocation(poss);
    },

    getCurrentCoords: async function() {
        return new Promise((resolve, reject) => {
           if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
              function(position) {
                  let pos = {
                    long: position.coords.longitude,
                    lat: position.coords.latitude,
                };
                resolve(pos);
              },
              function(err) {
                reject(err.message)
              }, {  enableHighAccuracy: true }
            );
           }else {reject(null)}
        });
    },

    ShowFooterHeader: function(opcion = 1) {
      if (opcion == 1) return this.$route.meta.hideHeader ? false : true;

      if (opcion == 2) return this.$route.meta.hideFooter ? false : true;
    },

    GetCoordinates(geoLocationPosition) {
      // console.log(geoLocationPosition)

      this.latitud = geoLocationPosition.coords.latitude;
      this.geoLocationPosition = geoLocationPosition;
    },

    GetCity() {},

    /*Evento que notifica si el usuario ingreso de nuevo sus credenciales */
    fnsuccess: async function(event) {
      if (event > 0) {
        this.showLogin = false;
      } else {
        this.forceCloseSession();
        this.showLogin = false;

        this.CheckRoute();
      }
    },

    MinutosRestantes() {
      let token = this.ObtenerToken();

      if (token) {
        /*Determina el rango de tiempo cuando el token esta por expirar */
        let valorMinimo = 2,
          valorMaximo = 6;

        /*Obtiene el tiempo de vigencia del token */
        let minutosrestantes = MinutosTokenRestante(token);
        //let fechaVencimientoToken = ObtenerFechaExpiracion(token);

        /*Emitimos el tiempo al modal */
        this.ToExpired = minutosrestantes;

        this.vMinutosRestantes = minutosrestantes;

        if (minutosrestantes > valorMinimo && minutosrestantes < valorMaximo) {
          /*Se encuentra en el rango de tiempo de expiración */
          //Mostrar Modal para ingresar de nuevas credenciales
          this.showLogin = true;
        }

        if (minutosrestantes < valorMinimo) {
          /*Token Vencido*/
          this.forceCloseSession();
          this.showLogin = false;

          this.CheckRoute();
        }
      } else {
        this.CheckRoute();
        // No se realiza acción, las rutas protegidas son verificadas por el router
      }
    },

    CheckRoute: function() {
      /*Verifica que se encuentra en una ruta que requiere autenticacion [con token] */
      let istime = this.$route.meta.requireAuth; //(this.$route.meta.requireAuth ? this.$route.meta.requireAuth : false);
      if (istime) {
        location.reload();
      }
    },

    ObtenerToken() {
      /*Obtiene el Token */
      let session = this.$session.get("token");
      if (!session) return null;
      return session;
    },
  },
};
</script>

<style src="./assets/tailwind.css" />
