import Vue from 'vue';
import  HCoreAuthService from '../../services/HSuite/HCore/auth/auth.service';
import { ProfileService } from '../../services/HSuite/Context/hsuite-rebyu-context';
import decode from 'jwt-decode';
import router from '../../router/index';
import crypto from '../../utils/crypto';
 import uuid from 'uuid';

import {MUTATION} from '../common/HMVuexCommon';
import { UUID, TOKEN, ATTEMPT, SECURITY, DISPATCH_SECURITY, DISPATCH_COMMIT_UUID, DISPATCH_ATTEMPT_USER, DISPATCH_USER} from '../common/HMConstans';
import HMAuthException from '../../models/exception/HMAuthException';


 import sweet from '../../utils/sweet';
// import HTTPSTATUS from '../utils/httpstatus';

export default  {
    namespaced: true,
    state: {
        token: Vue.prototype.$session ? Vue.prototype.$session.get(TOKEN): '',
        user: null,
        attempt : null,
        luuid: localStorage.getItem(UUID) || null,
        security: null,
        exception: null,
        unauthorized: false
    },

    /**
     * seccion con las mutaciones de las variables
     */
    mutations: {
        [MUTATION.HM_AUTH_SET_TOKEN] (state, token)  {
            state.token = token;
            if(token) {
                state.user = decode(token);                
                Vue.prototype.$session.set(TOKEN, token)
            }else {
                state.user = null;
            }
        },

        [MUTATION.HM_AUTH_SET_USER] (state, user)  {
            state.attempt = user;
            if(user) {
                Vue.prototype.$session.set(ATTEMPT, user)
            }else {
                state.attempt = null;
            }
        },
        
        [MUTATION.HM_AUTH_SET_LOCAL_UUID] (state, uuid) {
            state.luuid = uuid;
            if(uuid) {             
                localStorage.setItem(UUID, uuid);
            }else {
                state.luuid = null;
            }
        },
        [MUTATION.HM_AUTH_SET_SECURITY] (state, security)  {
            state.security = security;
            if(security) {
                Vue.prototype.$session.set(SECURITY, security)
            }else {
                state.security = null;
            }
        },
        [MUTATION.HM_AUTH_SET_DESTROY] (state)  {
            Vue.prototype.$session.destroy();
            state.token = null;
            state.attempt = null;
            state.security = null;
        },
        [MUTATION.HM_AUTH_SET_AUTH_EXCEPTION] (state, payload)  {
            state.exception = payload ? payload : null;
        },
        [MUTATION.HM_AUTH_SET_AUTH_UNAUTHORIZED] (state, payload)  {
            state.unauthorized = payload ? payload : false;
        },
    },

    actions: {
        async sigIn({commit, dispatch}, credentials) {
                commit(MUTATION.HM_AUTH_SET_AUTH_EXCEPTION, null)
                let response = await HCoreAuthService.sigIn(credentials) 
                if(response && response.success){
                    Vue.prototype.$session.start(); 
                    await dispatch(ATTEMPT, response.data.auth.token);
                    await dispatch(DISPATCH_SECURITY,  response.data.permission);
                    dispatch(DISPATCH_USER, response.data.desc_role);
                }else {
                    commit(MUTATION.HM_AUTH_SET_AUTH_EXCEPTION, new HMAuthException(response.success, response.message))
                }

                return response;
        },

        async setuser({dispatch, state}, role) {
            
            try {

                if(!state.user || !state.user.iduser) return;
                let response = await ProfileService.getByIdUser();
                if(response.success){
                    let data = {
                        name: response.data.name,
                        photo: response.data.profilePicture,
                        role: role
                    };
                    const encrypted =  crypto.encrypt(JSON.stringify(data));
                    dispatch(DISPATCH_ATTEMPT_USER, encrypted);
                }

                return response;

            } catch (error) {
                console.log(error)
            }

        },
        
        async setAnonymous({dispatch, state}) {
            try {
                if(state.luuid) return;
                let LOCAL_UUID = uuid.v4();
                dispatch(DISPATCH_COMMIT_UUID, LOCAL_UUID);
            } catch (error) {
                console.log(error)
            }
        },

        async attempt({commit}, token) {
            if(token) {
                commit(MUTATION.HM_AUTH_SET_TOKEN, token);
            }
        },

        async attemptsecury({commit},  security) {
           
            if(security) {
                const encryptedsecurity =  crypto.encrypt(JSON.stringify(security));
                commit(MUTATION.HM_AUTH_SET_SECURITY, encryptedsecurity);
            }
        },

        async attemptuser({commit}, user) {
            if(user) {
                commit(MUTATION.HM_AUTH_SET_USER, user);
            }
        },  

        async commitUUID({commit}, uuid) {
            if(uuid) {
                commit(MUTATION.HM_AUTH_SET_LOCAL_UUID, uuid);
            }
        }, 

        async forceCloseSession({commit}) {
            
            commit(MUTATION.HM_AUTH_SET_DESTROY);
            if(router.currentRoute.name == 'IndexHome') {
                window.location.reload();
            }else {
                router.push({name: 'Home'});
            }

        },

        async closeSession({commit}) {

            if(! await sweet.confirm("¿Desea cerrar la sesión?","","¡SI!","¡NO!","question")) return;

            HCoreAuthService.CloseSession();
            commit(MUTATION.HM_AUTH_SET_DESTROY);

            if(router.currentRoute.name == 'IndexHome') {
                window.location.reload();
            }else {
                router.push({name: 'Home'});
            }

        },
        getToken({commit}) {
            const token = Vue.prototype.$session.get(TOKEN)
            commit(MUTATION.HM_AUTH_SET_TOKEN, token ? token : null);
            
        },
        getLocal({commit}) {
            const localUUID = localStorage.getItem(UUID);
            if(localUUID) {
                commit(MUTATION.HM_AUTH_SET_LOCAL_UUID, localUUID);
            }else {
                commit(MUTATION.HM_AUTH_SET_LOCAL_UUID, null);
            }
        },
        getSecury({commit}) {
            const security = Vue.prototype.$session.get(SECURITY)
            if(security) {
                commit(MUTATION.HM_AUTH_SET_SECURITY, security);
            }else {
                commit(MUTATION.HM_AUTH_SET_SECURITY, null);
            }
        },
    },
    getters: {
        getuser (state) {
            return state.user;
        },
        attempt (state) {
            let encryped = Vue.prototype.$session.get(ATTEMPT);
            if(encryped) {
                state.attempt = crypto.decrypt(encryped);
            }
            return state.attempt ? JSON.parse(state.attempt) : '';
        },         
        isAuth(state) {
            return state.token ? true : false;
        },
        getLocal(state) {
            return state.luuid;
        },
        security(state) {
            let encryped = Vue.prototype.$session.get(SECURITY);
            if(encryped) {
                state.security = crypto.decrypt(encryped);
            }
            return state.security ? JSON.parse(state.security) : '';
        },
        exception(state) {
            return state.exception;
        },
    },


}