export default {
    //apis para core SIPM
    APP_CORE_SIGIN: '/api/auth/sigin',
    APP_CORE_CLOSE: '/api/auth/close',
    APP_CORE_CAT_SEX_GET_ALL: '/api/auth/sigin',
    APP_CORE_GET_USER: '/api/user/obtener/',
    APP_CORE_USER_SAVE: '/api/user/register',
    APP_CORE_GET_USER_BY_ID: '/api/user/get/',
    APP_CORE_CHECKMAIL: '/api/user/checkMail/',
    APP_CORE_GETINFORESSETPASSWORD: '/api/user/getCodeResetPassword',
    APP_CORE_RESSETPASSWORD: '/api/user/ressetPassword',
    APP_CORE_SEND_EMAIL_LINK: '/api/user/sendLinkConfirm',
    APP_CORE_CODEVERIFY: '/api/user/codeVerify',

    APP_CORE_ROLE_GET_BY_SYSTEM: '/api/role/get/system',

    APP_CATSEX_GET_ALL: '/api/catsex/getAll',

    APP_CORE_ADDRESS_REGISTER: '/api/address/register',

    APP_CORE_LICENSES_GET_BY_SYSTEM: '/api/licenses/getBySystem',
    APP_CORE_LICENSES_REGISTER: '/api/licenses/licenseRegister',
    APP_CORE_LICENSES_GET_BY_ID: '/api/licenses/get/',

    APP_REBYU_ENTITY_SAVE: '/api/entity/save',
    APP_REBYU_ENTITY_UPDATE: '/api/entity/update/',
    APP_REBYU_ENTITY_GET_BY_ID_USER: '/api/entity/get/user',
    APP_REBYU_ENTITY_GET_INFO: '/api/entity/getinfo/',
    APP_REBYU_ENTITY_GET_INFO_TAGNAME: '/api/entity/getstore/',
    APP_REBYU_ENTITY_GET_ALL: '/api/entity/getAll/',
    APP_REBYU_ENTITY_SEARCH: '/api/entity/search',

    APP_REBYU_ENTITY_REGISTER: '/api/entity/register',


    APP_REBYU_PROFILE_REGISTER: '/api/profile/register',
    APP_REBYU_PROFILE_GET_BY_ID_USER: '/api/profile/get/user',

    APP_REBYU_PROFILE_PICTURE_SAVE: '/api/profiluserpicture/save',

    APP_REBYU_ENTITY_CONFIG_GET: '/api/entityconfig/get/entity/',
    APP_REBYU_ENTITY_CONFIG_SAVE: '/api/entityconfig/save',


    APP_PRODUCTS_CATEGORY_SAVE: '/api/category/save',
    APP_PRODUCTS_CATEGORY_UPDATE: '/api/category/update/',

    APP_CORE_CATCOUNTRY_GET_ALL: '/api/location/country/getall',
    APP_CORE_CATSTATE_GET_ALL: '/api/location/state/getall/',
    APP_CORE_CATMUNICIPALITY_GET_ALL: '/api/location/municipality/getall/',
    APP_CORE_CATPOSTCODES_GET_ALL: '/api/location/postalcode/getall/',


    APP_REBYU_ENTITY_ADDRESS_SAVE: '/api/addressEntity/save',
    APP_REBYU_ENTITY_ADDRESS_GET_BY_ID_ENTITY: '/api/addressEntity/get/entity/',
    APP_REBYU_ENTITY_ADDRESS_GET_ALL_BY_ID_ENTITY: '/api/addressEntity/get/all/entity/',
    APP_REBYU_ENTITY_ADDRESS_GET_BY_ID: '/api/addressEntity/get/',
    APP_REBYU_ENTITY_ADDRESS_UPDATE: '/api/addressEntity/update/',
    APP_REBYU_ENTITY_ADDRESS_SET: '/api/addressEntity/set/',

    APP_REBYU_TAGS_GET_ALL: '/api/catTags/getAll',

    APP_REBYU_ENTITY_TAGS_SAVE: '/api/entity/saveTags',
    APP_REBYU_ENTITY_TAGS_GET_ENTITY: '/api/entity/get/tags/',


    APP_PRODUCTS_ENTITY_TAX_GET_ALL: '/api/entitytax/getAll/',
    APP_PRODUCTS_ENTITY_TAX_SET: '/api/entitytax/set/tax/',

    APP_PRODUCTS_CATEGORY_GET_BY_ID: '/api/category/get/entity/',
    APP_PRODUCTS_CATEGORY_STATUS: '/api/category/status/',

    APP_MENU_PRODUCT_CATEGORY_SAVE: '/api/menu/save/category',
    APP_MENU_PRODUCT_CATEGORY_GET_ALL: '/api/menu/get/category/',
    APP_MENU_PRODUCT_CATEGORY_GET_ALL_STORE: '/api/menu/get/category/store/',
    APP_MENU_PRODUCT_STATUS: '/api/menu/status/',
    APP_MENU_PRODUCT_GET_BY_ID: '/api/menu/get/',
    APP_PRODUCTS_PRODUCT_TAXES_GET_BY_ID_PRODUCT: '/api/taxes/get/product/',

    APP_MENU_PRODUCT_CATEGORY_UPDATE: '/api/menu/update/category/',

    APP_REBYU_ENTITY_TAGNAME_VALIDATOR: '/api/entity/tagname/validate',

    APP_REBYU_ENTITY_GET_STORE_CATEGORIES: '/api/entity/store/categories/',

    APP_REBYU_ENTITY_STATUS: '/api/entity/status/',

    APP_REBYU_ENTITY_GET_BY_ROWS: '/api/entity/getrows/',

    APP_REBYU_REVIEW_SAVE: '/api/review/save',

    APP_REBYU_REVIEW_GET_BY_ID_ENTITY: '/api/review/get/entity/',

    APP_REBYU_SHARED_LINK_SAVE: '/api/links/save',

    APP_REBYU_SHARED_LINK_GET_BY_ID_ENTITY: '/api/links/get/entity/',

    APP_REBYU_SHARED_LINK_STATUS: '/api/links/status/',

    APP_REBYU_SHARED_GET_BY_CODE: '/api/links/shared/',

    APP_REBYU_ENTITY_FOLLOWER_SAVE: '/api/follower/save',

    APP_REBYU_ENTITY_COVER_SAVE: '/api/entity/saveCoverPicture',

    APP_REBYU_ENTITY_PROFILE_SAVE: '/api/entity/saveProfilePicture',

    APP_REBYU_ENTITY_FOLLOWER_BY_USER: '/api/follower/favorite',

    APP_REBYU_SEARCH_ENGINE_GET_SUGGESTION_TAGS: '/api/search/get/suggestions/tags/',

    APP_REBYU_SEARCH_ENGINE_AUTOCOMPLETE: '/api/search/autocomplete',

    APP_REBYU_BUSINESS_LINE_GET: '/api/businessline/get/line',

    APP_REBYU_BUSINESS_LINE_VALUE_GET: '/api/businessline/get/line/value/',

    APP_PRODUCTS_SERVICE_GET_ALL: '/api/service/get/category/',

    APP_PRODUCTS_SERVICE_SAVE: '/api/service/save',

    APP_PRODUCTS_SERVICE_GET_BY_ID: '/api/service/get/',

    APP_PRODUCTS_SERVICE_DETAIL_SAVE: '/api/servicedetail/save',

    APP_PRODUCTS_SERVICE_DETAIL_GET_BY_SERVICE: '/api/servicedetail/get/service/',

    APP_PRODUCTS_SERVICE_DETAIL_STATUS: '/api/servicedetail/status/',

    APP_PRODUCTS_SERVICE_DETAIL_UPDATE: '/api/servicedetail/update/',

    APP_PRODUCTS_SERVICE_DETAIL_BY_ID: '/api/servicedetail/get/',

    APP_PRODUCTS_TOPPING_SAVE: '/api/topping/save',

    APP_PRODUCTS_TOPPING_UPDATE: '/api/topping/update/',

    APP_PRODUCTS_TOPPING_STATUS: '/api/topping/status/',

    APP_PRODUCTS_TOPPING_GET_BY_PRODUCT: '/api/topping/get/product/',

    APP_PRODUCTS_TOPPING_GET_BY_ID_PRODUCT: '/api/topping/get/id/product/',

    APP_PRODUCTS_TOPPING_VALUE_SAVE: '/api/toppingvalue/save',

    APP_PRODUCTS_TOPPING_VALUE_UPDATE: '/api/toppingvalue/update/',
    
    APP_PRODUCTS_TOPPING_VALUE_GET_BY_TOPPING: '/api/toppingvalue/get/topping/',
    
    APP_PRODUCTS_TOPPING_VALUE_GET_BY_ID: '/api/toppingvalue/get/',

    APP_PRODUCTS_TOPPING_VALUE_STATUS: '/api/toppingvalue/status/',

    APP_PRODUCTS_VARIANT_SAVE: '/api/variant/save',

    APP_PRODUCTS_VARIANT_UPDATE: '/api/variant/update/',

    APP_PRODUCTS_VARIANT_STATUS: '/api/variant/status/',

    APP_PRODUCTS_VARIANT_GET_BY_PRODUCT: '/api/variant/get/product/',

    APP_PRODUCTS_VARIANT_GET_BY_ID: '/api/variant/get/',

    APP_PRODUCTS_VARIANT_VALUE_SAVE: '/api/variantvalue/save',

    APP_PRODUCTS_VARIANT_VALUE_UPDATE: '/api/variantvalue/update/',

    APP_PRODUCTS_VARIANT_VALUE_GET_BY_VARIANT: '/api/variantvalue/get/variant/',

    APP_PRODUCTS_VARIANT_VALUE_GET_BY_ID: '/api/variantvalue/get/',

    APP_PRODUCTS_VARIANT_VALUE_STATUS: '/api/variantvalue/status/',

    APP_PRODUCTS_CAT_HOURS_GET_ALL: '/api/cat/hours/get/all',

    APP_PRODUCTS_SCHEDULE_SAVE: '/api/schedule/save',

    APP_PRODUCTS_SCHEDULE_UPDATE: '/api/schedule/update/',

    APP_PRODUCTS_SCHEDULE_GET_BY_ENTITY: '/api/schedule/get/entity/',

    APP_GETCSCHEDULE_GET_BY_CODE: '/api/schedule/get/CSchedule/',

    APP_PRODUCTS_SCHEDULE_GET_BY_CODE: '/api/schedule/get/code/',

    APP_PRODUCTS_DAYS_GET_ALL: '/api/days/getAll',
    //apis para REBYU

}