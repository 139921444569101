import { HCore } from "../../../config/axios.context";
import { APP_CORE_ROLE_GET_BY_SYSTEM } from '../../../utils/apiurl';

export default  {

    /**
     * Funcion que permite obtener los roles del sistema
     * @returns 
     */
    async getRolesBySystem() {
        try {
            const url = APP_CORE_ROLE_GET_BY_SYSTEM;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

}