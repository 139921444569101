import Api from '../config/ApiRebyu'
import api_url from '../../utils/api.url';

export default  {

    /**
     * Funcion que permite obtener los roles del sistema
     * @returns 
     */
    async getSuggestionTags(LIMIT = 0) {
        try {
            const url = api_url.APP_REBYU_SEARCH_ENGINE_GET_SUGGESTION_TAGS + LIMIT;
            return await Api.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener las sugerencias de busqueda del usuario
     * @param {*} term Termino dew busqueda
     * @param {*} id_user ID del usuario
     * @returns 
     */
    async autocomplete(term, id_user) {
        try {
            const url = api_url.APP_REBYU_SEARCH_ENGINE_AUTOCOMPLETE + (term ? ('?term=' + term + '&u='+id_user) : '');
            return await Api.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

}