<template>
  <div class="py-2 flex justify-center">
    <h6 :class="classes" class="font-sans text-base  mr-1">
      <slot name="title"></slot>
    </h6>
    <vue-typer
      v-if="suggestions && suggestions.length > 0"
      :text="suggestions"
      :repeat="Infinity"
      :shuffle="false"
      initial-action="typing"
      :pre-type-delay="70"
      :type-delay="70"
      :pre-erase-delay="2000"
      :erase-delay="250"
      erase-style="select-all"
      :erase-on-complete="false"
      caret-animation="solid"
    ></vue-typer>
    
  </div>
</template>

<script>
 import searcheable from '../../@core/mixins/searcheable'; 
export default {
    mixins: [searcheable],
    props: {
        suggestions: {
            type: Array,
            default: function() {return []}
        },
    },
    computed: {
      classes() {
        return {...this.searcheableTextClass}
      }
    }
};
</script>

<style scoped>
.vue-typer .custom.char {
  color: #D4D4BD;
  background-color: #1E1E1E;
}

.vue-typer .custom.caret {
  width: 10px;
  background-color: #3F51B5;
}
</style>
