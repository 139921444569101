<template>
  <v-card>
       <!-- <v-loading :is_loading="isloading" /> -->
       <v-modal-title>
            Ingrese sus credenciales para continuar
       </v-modal-title>
       <v-divider></v-divider>
       <!-- <v-skeleton v-if="loading" /> -->
        <v-card-text>

            <!--  -->
                <div class="mt-10 mx-auto  w-4/5 md:w-2/4">

                    <div class="mt-1" align="center">
                        <label class="font-sans font-bold tracking-wide text-gray-500">{{ Math.round(timeToExpired) }} {{'minutos'}} {{'para cierre de sistema'}}</label>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="mt-3">
                        <input v-model="$v.auth.email.$model" type="email" class="w-full border-solid border border-gray-300 px-3 py-2  shadow-sm
                                focus:outline-none focus:border-fucsia-dark rounded-lg
                                focus:ring-2 focus:ring-fucsia-lighter" placeholder="Correo electrónico" />
                        <small class="text-xs text-red-600" v-show="$v.auth.email.$anyDirty && !$v.auth.email.required">* El correo es requerido</small>
                    </div>
                    <div class="mt-4">
                        <div class="mt-1">
                            <input v-model="$v.auth.password.$model" v-on:keyup.enter="submit" id="pwd" type="password" name="user" class="w-full border-solid border  px-3 py-2 rounded-lg shadow-sm 
                                focus:outline-none focus:border-fucsia-dark 
                                focus:ring-2 focus:ring-fucsia-lighter" placeholder="Contraseña" />
                            <small class="text-xs text-red-600" v-show="$v.auth.password.$anyDirty && !$v.auth.password.required">* La contraseña es requerida</small>
                        </div>
                    </div>
                </div>

            <!--  -->

        </v-card-text>
       <v-card-actions>
          <v-spacer></v-spacer>
            <button
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                @click="close">Cerrar Sesion</button>
            <button
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
              @click="submit" >Iniciar Sesión</button>
        </v-card-actions>
  </v-card>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import toastMixin from "@/mixins/vue/toastmixin";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import AuthModel from "../../../models/auth/auth.model";

export default {
    name : "ModalLogin",
    mixins: [toastMixin,validationMixin],
    props:{
        timeToExpired : {
            type : Number,
            default : 0
        }
    },
    data: function() {
        return {

            auth: new AuthModel(),
            isloading: false,
            loading: false,
        }
    },
    validations: {
        auth: {
            email: {
                required
            },
            password: {
                required
            }
        }
    },


    methods: {
        
        ...mapActions({
            sigIn: 'auth/sigIn'
        }),

        async close(){
            this.$emit('success',0);
        },

        async submit() {

            this.$v.$touch();

            if(!this.$v.$anyError) {

                let response =  await this.sigIn(this.auth);

                if(response.success){
                    if(this.user) {
                        this.$emit('success',1);
                    }
                } else{
                     this.failure(response.mensage);
                }
            }else {
                this.warning('', 'Complete los datos requeridos');
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/getuser',
        })
    }
}
</script>

<style>

</style>