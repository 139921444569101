<template>
  <div class=" bg-gray-100 min-screen-content">
    <r-nav-bar />
    <main class="mt-14 min-screen-content" style="height:100%">
      <slot></slot>
      <r-footer/>
    </main>
   
  </div>
</template>

<script>
import navbar from './components/admin/navbar.vue';
import footer from './components/admin/footer.vue';

export default {
    components: {
      'r-nav-bar': navbar,
      'r-footer': footer
    },
}
</script>
