<template>
   <div class="">
        <div id="overlay" v-if="is_loading" :class="fullpage ? 'fixed' : 'relative'" class="flex items-center justify-center">
            <div>
                <lottie-animation
                    :path="'lottie/loader.json'" 
                    :width="250"
                    :height="250"     
                    :speed="1.5"     
                />
                <div class="text-center">
                    <span class="text-black font-sans font-bold text-lg text-center tracking-wide w-full">{{message ? message : ''}}</span>
                </div>
            </div>
        </div>
      
    </div>

</template>


<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    props: {
        is_loading: {
            type: Boolean,
            default: false
        },
        fullpage: {
            type: Boolean,
            default: true
        },
        message: {
            type: String,
            default: 'Espere, por favor...'
        }
    },
    components: {
        LottieAnimation
    },

}
</script>

<style scoped>
    #overlay {
        display: visible; /* Hidden by default */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
        background-color: rgb(33, 33, 33, 0.25);
        border-color: rgb(33, 33, 33, 0.25);
    }
</style>
