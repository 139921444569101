<template>
<header class="fixed inset-x-0 z-50 w-auto">
  <nav class="bg-navbar w-auto h-16 shadow-xl">
    <div class=" flex flex-wrap justify-between items-center mx-auto  h-16">

        <div class="ml-7 mt-1 w-24 h-9 cursor-pointer" @click="refresh()" >
            <v-img  max-height="150" width="250"  :src="imgLogotype">
              </v-img>
        </div>

         <span class="text-3xl cursor-pointer mx-5 flex justify-end items-center md:hidden text-fucsia-dark">
                <f-icon @click="openmenu = !openmenu" :icon="['fas', 'bars']"></f-icon>
          </span>

        <div class="hidden w-full md:flex md:w-auto md:items-center h-16" id="mobile-menu">
          
            <ul class="flex items-center">
              <li :class="nvbBuscador" id="nvb-buscador">  
                <div class="mt-3">
                  <h5 @click="goToDiv('buscador')" class="cursor-pointer block py-2 pr-6 pl-4">Buscador</h5>
                </div>
              </li>
              <li :class="nvbBeneficios" id="nvb-beneficios">
                <div class="mt-3">
                  <h5 @click="goToDiv('beneficios')" class="cursor-pointer block py-2 pr-6 pl-4">Beneficios</h5>
                </div>
              </li>
              <li :class="nvbAcerca" id="nvb-acerca">
                <div class="mt-3">
                  <h5 @click="goToDiv('acerca')" class="cursor-pointer block py-2 pr-6 pl-4">Acerca</h5>
                </div>
              </li>
              <li :class="nvbLicencias" id="nvb-licencias">
                <div class="mt-3">
                  <h5 @click="goToDiv('licencias')" class="cursor-pointer block py-2 pr-6 pl-4">Licencias</h5>
                </div>
              </li>
              <li :class="nvbSocios" id="nvb-agregados" >
                <div class="mt-3">
                  <h5 @click="goToDiv('socios')" class="cursor-pointer block py-2 pr-6 pl-4">Últimos agregados</h5>
                </div>
              </li>
              <li :class="nvbComunidad" id="nvb-comunidad" >
                <div class="mt-3">
                  <h5 @click="goToDiv('comunidad')" class="cursor-pointer block py-2 pr-6 pl-4">Comunidad</h5>
                </div>
              </li>
            </ul>
         
        </div>  

         <div v-if="isauth" class="md:flex md:justify-end items-center hidden px-5 mr-5" >
             <div class="mr-4 grid" >
                    <span class="text-sm font-bold tracking-wider mb-0 text-navbar-text" >{{username ? username.name : ''}} </span>
                    <small class="text-xs text-center w-full text-navbar-text tracking-wide">{{username.role ? username.role : ''}}</small>
              </div>
              <v-menu class="shadow-md max-w-full sm:w-full" transition="slide-y-transition" offset-y v-model="isDropdown" style="max-width: 100%; width: 100%;">

                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="rounded-full px-2 py-2 cursor-pointer" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-navbar-text" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                </template>

                <div class="mt-2">
                  <transition name="slide-fade" v-show="isDropdown">
                            <account-dropdown/>
                  </transition>
                </div>

              </v-menu>
         </div>
         <div v-else class="hidden md:flex md:justify-end items-center px-5 bg-gray-300 h-full">
              <router-link v-if="!isauth" to="/login" >
                  <div  class="rounded-full cursor-pointer" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-navbar-text" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                  </div>
                </router-link>
         </div>
    </div>

    <!-- MENU MOVIL -->
     <v-dialog
          v-model="openmenu"
          fullscreen
          persistent
          transition="dialog-top-transition"
          class="block md:hidden"
        >
      <v-card class="bg-white pl-0 shadow-md  left-0 py-4 md:hidden block transition-all duration-300" style="border-radius: 0px;" >
         <div class="flex justify-end px-5 py-3">
              <svg  @click="openmenu = !openmenu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg text-gray-400 hover:text-fucsia-lighter" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </div>
            <div class="py-5 px-3 mx-7" v-if="isauth">
                <div class="flex gap-7 items-center">
                    <div class="bg-cover h-20 w-20 rounded-full  border-2 border-gray-300" :style="`background-image: url('`+(username.photo ? username.photo : DEFAULT_IMG)+`')`"></div>
                    <div class="grid">
                        <span class="font-sans tracking-wide font-bold text-2xl">{{username ? username.name : ''}} </span>
                        <span class="text-gray-400 tracking-wide font-sans text-lg font-semibold text-center">{{username.role ? username.role : ''}}</span>
                    </div>
                </div>
            </div>
            <div class="grid gap-y-10 pt-10 mx-7 ">
                <div class="grid gap-y-10" v-if="isauth">
                  <router-link to="/profile" >
                    <div class="mx-4 flex gap-10 items-center" @click="openmenu = !openmenu">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span :class="currenturl == 'ProfileUser' ? 'text-fucsia-lighter' : 'text-gray-600'" class="text-lg  font-semibold tracking-wider duration-500">Perfil</span>
                    </div>
                  </router-link>
                <div class="mx-4 flex gap-10 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Historial</span>
                </div>
                <div class="mx-4 flex gap-10 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Configuración</span>
                </div>
                  <router-link to="/favorite">
                  <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                      <span :class="currenturl == 'Favorites' ? 'text-fucsia-lighter' : 'text-gray-600'" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Favoritos</span>
                  </div>
                  </router-link>
                </div>
                <v-divider v-if="isauth" ></v-divider>
              <router-link to="/home" >
                <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-house text-gray-400" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                    <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                    </svg>
                    <span :class="  currenturl == 'IndexHome' ? 'text-fucsia-lighter' : 'text-gray-600'" class="text-lg font-semibold tracking-wider duration-500">Inicio</span>
                </div>
              </router-link>
              <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search text-gray-400" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                    <span @click="goToDiv('buscador')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Buscador</span>
             </div>
             <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-wallet2 text-gray-400" viewBox="0 0 16 16">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                <span @click="goToDiv('beneficios')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Beneficios</span>
              </div>
             <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle text-gray-400" viewBox="0 0 16 16">
                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                 <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
                <span @click="goToDiv('acerca')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Acerca de</span>
              </div>
              <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-lines-fill text-gray-400" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
                </svg>
                <span @click="goToDiv('licencias')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Licencias</span>
              </div>
              <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-lines-fill text-gray-400" viewBox="0 0 16 16">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                </svg>
                <span @click="goToDiv('socios')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Últimos agregados</span>
              </div>
              <div class="mx-4 flex gap-10 items-center"  @click="openmenu = !openmenu">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-lines-fill text-gray-400"  viewBox="0 0 16 16">
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                <span @click="goToDiv('comunidad')" class="text-lg text-gray-600 font-semibold tracking-wider duration-500">Comunidad</span>
              </div>

           </div>

          <div class="w-full px-3 py-3" v-if="isauth">
            <button class="btn-full-fucsia flex justify-center items-center gap-3" @click="logout()">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                <span class="text-lg font-medium tracking-wider duration-500">Salir</span>
            </button>
         </div>

          
          <router-link to="/auth/register" v-if="!isauth" class="absolute bottom-14 w-full px-3" >
                <button class="btn-full-gray flex justify-center items-center gap-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                  <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
                  <span class="text-lg  font-medium tracking-wider duration-500">Crear una cuenta</span>
                </button>
           </router-link>
          <router-link to="/login" v-if="!isauth" class="absolute bottom-1 w-full px-3" >
                <button class="btn-full-fucsia flex justify-center items-center gap-3">
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                    <span class="text-lg  font-medium tracking-wider duration-500">Iniciar Sesión</span>
                </button>
           </router-link>
           

         
          
      </v-card>
     </v-dialog>
  </nav>
</header>
</template>

<script>
import {mapActions, mapGetters } from "vuex";
import vaccount from '@/@core/components/global/account.vue';

export default {


    name: 'NavBar',
    components: {
      'account-dropdown': vaccount
    },
    data: function() {
    return {
       openmenu: false,
       isDropdown: false,
       DEFAULT_IMG: 'https://res.cloudinary.com/dx3omcygd/image/upload/v1644090203/admin/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55_ionkae.jpg',
       nvbBuscador:'bg-fucsia-lighter text-white h-16',
       nvbBeneficios:'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16',
       nvbAcerca:'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16',
       nvbLicencias:'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16',
       nvbSocios:'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16',
       nvbComunidad:'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16',
       navSeleccionadoActual:'buscador',
       imgLogotype:require('@/assets/logotype/logo_fullcolor.png')
    }
  },
  created(){
  
  },
  methods : {
    ...mapActions({
      closeSession: 'auth/closeSession'
    }),
    logout: function() {
      this.openmenu = false;
      this.closeSession();
    },
    goToDiv(iddiv)
    {
      document.getElementById(iddiv).scrollIntoView({ behavior: 'smooth' });
      switch(iddiv)
      {
        case 'buscador':
          this.SetClassAtributes()
          this.nvbBuscador = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'buscador'
        break;

        case 'beneficios':
          console.log('1')
          this.SetClassAtributes()
          this.nvbBeneficios = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'beneficios'
          console.log('2')
        break;        

        case 'acerca':
          this.SetClassAtributes()
          this.nvbAcerca = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'acerca'
        break;
         
        case 'licencias':
          this.SetClassAtributes()
          this.nvbLicencias = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'licencias'
        break;

        case 'socios':
          this.SetClassAtributes()
          this.nvbSocios = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'socios'
        break;

        case 'comunidad':
          this.SetClassAtributes()
          this.nvbComunidad = 'bg-fucsia-lighter text-white h-16';
          this.navSeleccionadoActual = 'comunidad'
        break;
      }

    },

    SetClassAtributes(){
      switch(this.navSeleccionadoActual)
      {
        case 'buscador':
          this.nvbBuscador = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
        break;

        case 'beneficios':
          this.nvbBeneficios = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
          
        break;        

        case 'acerca':
          this.nvbAcerca = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
        break;
         
        case 'licencias':
          this.nvbLicencias = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
        break;

        case 'socios':
          this.nvbSocios = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
        break;

        case 'comunidad':
          this.nvbComunidad = 'hover:bg-fucsia-lighter hover:text-white text-navbar-text h-16';
        break;

        
      }
    },
    
    refresh(){
      window.location.href = '/home';
    }
    
  },
  computed: {
      ...mapGetters({
        isauth: 'auth/isAuth',
        username: 'auth/attempt'
      }),
    currenturl: function() {
      return this.$route.name;
    }
  }


}
</script>

<style scoped>

</style>