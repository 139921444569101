export default {

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
    
                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.readAsDataURL(file);
            } catch (error) {
                reject(null);
            }
            
        });
    },
    
    async getFileDimensions(entry) {
        return new Promise((resolve, reject) => {
            try {
                var image = new Image(); 
                image.src = entry;
                image.onload = function() {
                  resolve({width: this.width, height: this.height});
          
                };
            } catch (error) {
                reject(null);
            }
        });
    },

    async b64toBlob(b64Data, contentType='') {
        return new Promise((resolve, reject) => {

            try {
                var byteString = atob(b64Data.split(',')[1]);
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob =  new Blob([ab], { type: contentType });
                    
                resolve(blob);
            }catch (error) {
                reject(error);
            }

        })
      }
    
    }
    