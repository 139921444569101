import {MUTATION} from '../../common/HMVuexCommon';
import { EntityService } from '../../../services/HSuite/Context/hsuite-rebyu-context'

export default  {
    namespaced: true,
    state: {
        entities: [],
        entity: {}

    },
    actions: {
        async getEntities({commit}) {
            let response = await EntityService.getByIdUser()
            if(response && response.success){
                commit(MUTATION.RB_ENTITY_SET_ENTITIES, response.data)
            }
        },
        async getEntity({commit}, id) {
            let response = await EntityService.getInfo(id)
            if(response && response.success){
                commit(MUTATION.RB_ENTITY_SET_ENTITY, response.data)
            }
        },
    },

    getters: {
        getEntities (state) {
            return state.entities;
        },
        getEntity (state) {
            return state.entity;
        },
    },

    mutations: {
        [MUTATION.RB_ENTITY_SET_ENTITIES] (state, payload) {
            state.entities = payload ? payload : []
        },
        [MUTATION.RB_ENTITY_SET_ENTITY] (state, payload) {
            state.entity = payload ? payload : {}
        }
    }

}