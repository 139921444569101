import { HCore } from '../../../config/axios.context'
import { APP_CORE_LICENSES_GET_BY_SYSTEM, APP_CORE_LICENSES_REGISTER,
    APP_CORE_LICENSES_GET_BY_ID } from '../../../utils/apiurl';

export default  {

    /**
     * Funcion que permite obtenerlas licensias del sistema
     * @returns 
     */
    async getLicenses() {
        try {
            const url = APP_CORE_LICENSES_GET_BY_SYSTEM;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Permite registrar la licencia del usuario
     * @param {*} data Modelo de datos de la licencia
     * @returns 
     */
    async register(data) {
        try {
            const url = APP_CORE_LICENSES_REGISTER;
            return await HCore.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Obtiene los datos de una licencia con base al id
     * @param {String} id id de la licencia
     * @returns 
     */
    async getbyId(id) {
        try {
            const url = APP_CORE_LICENSES_GET_BY_ID + id;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

}