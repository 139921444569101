import { HCore } from "../../../config/axios.context";
import { APP_CATSEX_GET_ALL } from '../../../utils/apiurl';

export default  {

     /**
     * Funcion que permite obtener la de generos
     * @returns 
     */
      async getAll() {
        try {
            const url = APP_CATSEX_GET_ALL;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },


}