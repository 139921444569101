<template>
    <v-skeleton-loader
        class="mx-auto mt-2"
        :max-width="width"
        :type="type"
    ></v-skeleton-loader>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "card, list-item-two-line"
        },
        width: {
            type: String,
            default: "600"
        }
    }

}
</script>

<style>

</style>