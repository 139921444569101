import config from "../config/config";
import CryptoJS from 'crypto';

const algorithm = "aes-256-cbc"; 

export default {

    /**
     * Función que permite encriptar el valor ingresado
     * @param {any} value texto a encriptar
     * @returns 
     */
    encrypt(value) {
        const cipher  = CryptoJS.createCipheriv(algorithm, config.PRIVATE_ENCRYPT_KEY, config.PRIVATE_ENCRYPT_VECTOR);
        let encryptedData = cipher.update(value, "utf-8", "hex");
        encryptedData += cipher.final("hex");
        return encryptedData;
    },

    /**
     * Función que permite desencriptar el valor ingresado
     * @param {any} value Valor encriptado
     * @returns 
     */
    decrypt(value) {
        const decipher = CryptoJS.createDecipheriv(algorithm, config.PRIVATE_ENCRYPT_KEY, config.PRIVATE_ENCRYPT_VECTOR);
        let decryptedData = decipher.update(value, "hex", "utf-8");
        decryptedData += decipher.final("utf-8");
        return decryptedData;
    }
    
}


