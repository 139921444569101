<template>
    <small>
            <slot></slot>
    </small>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            validation: {
                type: Object,
                default: function () {return {} }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>