<template>
    <div class="map-responsive">
        {{location}}
        <iframe
        width="100%"
        height="500"
        style="border:0"
        loading="lazy"
        allowfullscreen
        frameborder="0"
        referrerpolicy="no-referrer-when-downgrade"
        :src="'https://www.google.com/maps/embed/v1/place?key='+MAP_KEY+'&q='+location">
        </iframe>
    </div>
</template>

<script>
export default {
    props: {
        location: {
            type: String,
            default: '0,0'
        }
    },
    data: function() {
        return {
            MAP_KEY: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        }
    }
}
</script>

<style scoped>
        .map-responsive{

        overflow:hidden;

        padding-bottom:5%;

        position:relative;

        height: auto;

        min-height: 500px;

        }

        .map-responsive iframe{

        left:0;

        top:0;

        max-height: 100%;

        width:100%;

        min-height: 500px;

        position:absolute;

        }
</style>>
