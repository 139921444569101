import licences from '../HCore/licenses/license.service';
import auth from '../HCore/auth/auth.service';
import catsex from '../HCore/catalog/catsex.service';
import location from '../HCore/catalog/location.service';
import role from '../HCore/catalog/role.service';
import person from '../HCore/user/person.service';
import address from '../HCore/address/admaddress.service';


export const LicenceService = licences;
export const AuthService = auth;
export const CatSexService = catsex;
export const LocationService = location;
export const RoleService = role;
export const PersonService = person;
export const AddressService = address;


