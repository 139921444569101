
import user from './user';

export default [
  ...user,
    {
      path:'/home',
      name:'IndexHome',
      component: () => import('../../views/dashboard/Home.vue'),
      meta: {title: 'Home - Rebyu', layout: 'home'},
    },

    {
      path:'/searcher',
      name:'Buscador',
      component: () => import('../../views/search/RBGlobalSearch.vue'),
      meta: {title: 'Mi Tienda - Rebyu', layout: 'admin'},
      props: route => ({name: route.params.name, query: route.query.busqueda})
    },
    
    {
      path:'/about',
      name:'about',
      component: () => import('../../views/dashboard/About.vue'),
      meta: {title: 'About - Rebyu'},
    },
    {
      path:'/prices/:id',
      name:'Prices',
      component: () => import('../../views/license/license.vue'),
      meta: {title: 'Carrito de compra', layout: 'admin'},
      props: route => ({name: route.params.name})
    },
    {
      path:'/:name',
      name:'MiTienda',
      component: () => import('../../views/entity/store.vue'),
      meta: {title: 'Mi Tienda - Rebyu', layout: 'admin'},
      props: route => ({name: route.params.name})
    },

]