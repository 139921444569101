import { HProducts } from "./axios.instance"
import Httphelper from './httphelper';
import HttpStatus from '../../utils/http/httpstatus';
import ResultData from '../../models/global/HMResultData.model'

    /**
     * Realiza una petición de tipo get a la url indicada
     * @param {String} url url 
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async function get(url) { 
      let response = new ResultData();
        try {
            
            let result = await HProducts.get(url, Httphelper.getToken());
            if(result && result.status == HttpStatus.SUCCESS) {
              response = {...result.data, success: true}
            }
        } catch (error) {
              response = {...error.response.data, success: false}
        }
        return response;
    }
    
    /**
     * Realiza una petición de tipo post a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async function post(url, payload) { 
        let response = new ResultData();
        try {
            let result = await HProducts.post(url, payload, Httphelper.getToken());
            if(result && result.status == HttpStatus.SUCCESS) {
              response = {...result.data, success: true}
            }
        } catch (error) {
             response = {...error.response.data, success: false}
        }
        return response;
    }
    
    /**
     * Realiza una petición de tipo put a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns 
     */
    async function put(url, payload) { 
      let response = new ResultData();
        try {
            let result = await HProducts.put(url, payload, Httphelper.getToken());
            if(result && result.status == HttpStatus.SUCCESS) {
              response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response;
    }     

export default  {
    get,
    post,
    put
}