export default [
    {
      path:'/shared',
      name:'shared',
      component: () => import('../../views/shared/index.vue'),
      meta: {title: 'Comparte - Rebyu'},
      props: route => ({code: route.params.code, type: route.meta.type}),
      children: [
          {
            path:'rebyu/:code',
            name:'rebyu',
            component: () => import('../../views/shared/index.vue'),
            meta: {title: 'Rebyu - Rebyu', type: 'LINK_REVIEW',  layout: 'default'},
          },
          {
            path:'menu/:code',
            name:'rebyumenu',
            component: () => import('../../views/shared/index.vue'),
            meta: {title: 'Menu - Rebyu', type: 'LINK_MENU',  layout: 'default'},
          }
      ]
    },

]