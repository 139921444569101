import ApiRebyu from '../../../config/ApiRebyu';
import api_url from '../../../../utils/api.url';

export default  {

    /**
     * Función que se encarga de guardar los datos de la entidd
     * @param {any} data Modelo de datos de la entidad
     * @returns 
     */
    async save(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite actualizar los datos de la entidad
     * @param {*} id ID de la entidad
     * @param {*} data Modelo de datos e la entidad
     * @returns 
     */
    async update(id, data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_UPDATE + id;
            return await ApiRebyu.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },
    
    /**
     * Función que permite obtener la lista de entidades por usuario
     * @returns Lista de entidades
     */
     async getByIdUser() {
        try {
            const url = api_url.APP_REBYU_ENTITY_GET_BY_ID_USER;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite obtener los datos de la entidad
     * @param {String} id identificado de la Entidad
     * @returns 
     */
     async getInfo(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_GET_INFO + id;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },
        /**
     * Función que permite obtener los datos de la entidad
     * @param {String} tagname tagname de la Entidad
     * @returns 
     */
         async getInfoTagName(tagname, id_user = null) {
            try {
                const url = api_url.APP_REBYU_ENTITY_GET_INFO_TAGNAME + tagname + (id_user ? '?u='+ id_user : '');
                return await ApiRebyu.get(url);
            } catch (error) {
                return error.response.data;
            }
        },

     /**
     * Función que permite obtener todas las entidades
     * @returns 
     */
    async getAllEntitys() {
        try {
            const url = api_url.APP_REBYU_ENTITY_GET_ALL;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

     /**
     * Función que se encarga de guardar los tags de la entidad
     * @param {any} data Modelo de datos de los tags
     * @returns 
     */
      async saveTags(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_TAGS_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite obtener los tags de la entidad
     * @param {String} id Identificador de la entidad
     * @returns 
     */
    async getTagsByEntity(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_TAGS_GET_ENTITY + id;
            return await ApiRebyu.get(url)
        } catch (error) {
            return error.response.data;
        }
    }, 
    /**
     * Función que permite validar si el TagName es valido
     * @param {String} tag TagName de la Entidad
     * @returns 
     */
    async tagNameValidator(tag) {
        try {
            const url = api_url.APP_REBYU_ENTITY_TAGNAME_VALIDATOR + ( tag ? '?tag='+ tag : '');
            return await ApiRebyu.get(url)
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Permite obtener las categorias y productos de la entidad
     * @param {String} id_entity ID de la entidad o Tagname
     * @returns 
     */
    async getStoreCategories(id_entity) {
        try {
            const url = api_url.APP_REBYU_ENTITY_GET_STORE_CATEGORIES + id_entity;
            return await ApiRebyu.get(url)
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite actualizra el estatus de la entidad
     * @param {*} id_entity 
     * @param {*} data 
     * @returns 
     */
    async status(id_entity, data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_STATUS + id_entity;
            return await ApiRebyu.put(url, data)
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite obtener la lista de entidades por ROWS 
     * @param {*} rows ROWS
     * @returns 
     */
    async getByRows(rows) {
        try {
            const url = api_url.APP_REBYU_ENTITY_GET_BY_ROWS + rows;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Permite realizar la busqueda de entidades
     * @param {*} data Modelo de busqueda
     * @returns 
     */
    async search(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_SEARCH;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    
    /**
     * Función que se encarga de guardar los datos de la entidd
     * @param {any} data Modelo de datos de la entidad
     * @returns 
     */
     async register(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_REGISTER;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Funciónn que permite actualizar la foto de portada de la entidad
     * @param {*} data Modelo de datos de la foto
     * @returns 
     */
    async saveCoverPicture(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_COVER_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Funciónn que permite actualizar la foto de perfil de la entidad
     * @param {*} data Modelo de datos de la foto
     * @returns 
     */
         async saveProfilePicture(data) {
            try {
                const url = api_url.APP_REBYU_ENTITY_PROFILE_SAVE;
                return await ApiRebyu.post(url, data);
            } catch (error) {
                return error.response.data;
            }
        }

}
