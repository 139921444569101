import core from './ApiCore';
import rebyu from './ApiRebyu';
import products from './ApiProducts';

/**Api del Hemia Core */
const HCore = core;
/**Api de Hemia Rebyu */
const HRebyu = rebyu;
/**Api de Hemia Products */
const HProducts = products;


export {
    HCore,
    HRebyu,
    HProducts
}