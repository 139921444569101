<template>
    <v-menu transition="slide-y-transition" bottom offset-y v-model="isopensearch">
        <template  v-slot:activator="{ on, attrs }" >
            <div class="flex justify-center  mt-7  mx-5 rounded-lg" :class="inputTypeClass">  
                 <v-text-field
                  @keydown.enter="handlesearch(false)"
                  @keydown.down="handlekeydown"
                  @keydown.up="handlekeyup"
                    v-model="$v.search_data.search.$model"  
                  v-on="on" v-bind="attrs" 
                    hide-details
                    solo
                    class="search-input-content"
                    :class="inputTypeClass" type="text"
                    background-color="transparent"
                    color="grey lighten-5"
                >
                <template slot="label">
                  <rb-search-suggest :suggestions="suggestions" :home="home" :search="search" />
                </template>
                </v-text-field>
                <button @click="handlesearch(true)" type="button"
                    class="mx-auto sm:w-12 md:w-16 h-12  py-2 sm:px-2 md:px-4 bg-opacity-30 border border-solid border-white rounded-r-lg rounded-l-none text-white" :class="buttonTypeClass">
                    <v-icon v-if="!loadingsearch" size="32px" color="white">mdi-magnify</v-icon>
                </button>
            </div>
        </template>
        <v-card class="mx-0 md:mx-5 px-5 py-3">

            <v-search-suggestion :suggestiontags="suggestions" v-if="suggestions && suggestions.length > 0 && (!searchautocompletedata || searchautocompletedata.length <= 0)" />

            <div class="grid gap-y-1 py-1" v-if="searchautocompletedata && searchautocompletedata.length > 0">
                <div class="flex gap-2 items-center">
                    <f-icon :icon="['fas', 'layer-group']" />
                    <span class="text-sm tracking-wide font-sans">Sugerencias</span>
                </div>
            </div>

            <div class="flex items-center py-5"
                v-if="search_data.search && (searchautocompletedata && searchautocompletedata.length <= 0)">
                <div class="flex gap-2 items-center justify-center items-center text-gray-400 text-lg">
                    <f-icon :icon="['fas', 'ban']" />
                    <span class="tracking-wide font-sans">No se encontraron resultados</span>
                </div>
            </div>
            <v-list @keydown="handlekeydown">
                <v-list-item-group @keydown="handlekeydown">
                    <v-list-item v-for="(term, i) in searchautocompletedata" v-bind:key="i" v-bind:tabindex="i" link
                        @keydown="handlekeydown">
                        <router-link :to="term.url" class="w-full flex">
                            <v-list-item-icon>
                                <svg v-if="term.type == 'TAG'" xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 text-gray-700 font-bold" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <svg v-if="term.type == 'ENTITY'" xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6  text-gray-700 font-bold" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                            </v-list-item-icon>
                            <v-list-item-title v-html="term.label"
                                class="text-gray-600 font-sans tracking-wide font-normal cursor-pointer w-full">
                            </v-list-item-title>
                        </router-link>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <small class="absolute right-1 bottom-0 text-fucsia-lighter tracking-wide font-sans">Powered by
                <b>RebyÜ</b></small>
        </v-card>

    </v-menu>

</template>

<script>
import ToastMixin from '../../mixins/vue/toastmixin.js';
import searchEngineService from '../../services/searchengine/searchengine.service';
import {validationMixin} from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Search from '../../models/entity/search.model';
import vsearchsuggestioncomponentVue from './RBSearchSuggestionInput.vue';
import VSearchSuggestionTyperVue from "../searchbar/RBSearchSuggestionTyper.vue";
import searcheable from '../../@core/mixins/searcheable';

export default {
     mixins: [ToastMixin, validationMixin, searcheable],
     props: {
        suggestions: {
            type: Array,
            default: function() {return []}
        },
     },
     computed: {
        inputTypeClass() {
          return {...this.searcheableInputClass}
        },
        buttonTypeClass() {
          return {...this.searcheableButtonClass}
        }
     },
     data: function() {
        return {
            loadingsearch: false,
            isopensearch: false,
            search_data: new Search(),
            searchsuggestiontags:[],
            searchautocompletedata: [],
            arrowCounter: -1,
        }
    },
    components: {
        'v-search-suggestion': vsearchsuggestioncomponentVue,
        "rb-search-suggest": VSearchSuggestionTyperVue,
    },
    validations :{
      search_data: {
        search: {
          required
        }
      }
  },

  methods: {

    onSearch: async function() {
      if(this.search_data.search.includes('@')){
          var new_ruta = this.search_data.search.replace('@','')
          location.href = '/' + new_ruta
          return;
      }

      this.$v.$touch();
      if(!this.$v.$anyError) {
        this.loadingsearch = true;
        let response = await searchEngineService.autocomplete(this.search_data.search, '0');
        this.loadingsearch = false;
        if(response.success) {
          this.searchautocompletedata = response.data;
        }
      }

    },

    handlesearch: function(isbtn = false) {
      this.$v.$touch();
      if(!this.$v.$anyError) {
        if(isbtn) {
          window.location.href = '/searcher?busqueda=' + this.search_data.search;
        }else {
          if(this.arrowCounter == -1) {
             window.location.href = '/searcher?busqueda=' + this.search_data.search;
          }else {
             let selected =  this.searchautocompletedata[this.arrowCounter];
              window.location.href = selected.url;
          }
        }
      }
    },

    handlekeydown: function() {
      if (this.arrowCounter < this.searchautocompletedata.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    handlekeyup: function() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

  },
 
  watch: {
    'search_data.search' : function() {
      if(this.search_data.search.length < 4) {
        this.searchautocompletedata = [];
      }

      if(this.search_data.search.length >= 4) {
          this.isopensearch = true;
          this.onSearch();
      }else {
         this.isopensearch = false;
      }
    }
  }


}
</script>

<style scoped>

.home-wd {
  max-width: 100%;
}

.home-wd-md {
  max-width: 720px;
}

.searcher {
   max-width: 100%;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(255, 255, 255, 0.986);
}

.v-text-field--outlined fieldset {
    color: white !important;
}

.v-text-field.v-text-field--solo >>> .v-input__control {
    min-height: 45px !important;
    padding: 0 !important;
    display: flex !important;
}


.v-text-field.v-text-field--solo >>> .v-input__slot {
    align-items: center;
    color: inherit;
    display: block;
    min-height: inherit;
    position: relative;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
    box-shadow: none !important
}

.v-text-field.v-text-field--solo >>> .v-input input {
    max-height: 50px !important;
}

.v-text-field.v-text-field--solo >>> .v-text-field.v-text-field--solo .v-label {
     top: 0px !important
}

.v-text-field.v-text-field--solo >>> .v-input__control input {
    caret-color: auto;
    max-height: 45px !important;
    height: 45px !important;
}

.v-text-field.v-text-field--solo >>> .v-label {
    height: 45px !important;
    max-height: 45px !important;
    top: 5px !important;
}

  .home-input-text >>> .v-text-field__slot input {
    color: white
  }

   .searcher-input-text >>> .v-text-field__slot input {
    color: lightgray
  }
</style>