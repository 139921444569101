<template>
  <div class="h-full w-full">
       <v-navbar></v-navbar>
       <slot> </slot>
       <v-footer></v-footer>
  </div>
</template>

<script>
import NavBar from '@/@core/layouts/components/home/navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
    components:{ 
      'v-navbar':NavBar, 
      'v-footer':Footer,
    }

}
</script>

<style>

</style>