import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import store from './store'
import * as VueGoogleMaps from "vue2-google-maps";
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/libs/toastification';
import '@/libs/filters';
import '@/libs/directives';
import QrcodeVue from 'qrcode.vue';
import InputMask from 'vue-masked-input';
import UUID from "vue-uuid";
import Flicking from "/node_modules/@egjs/vue-flicking";
import "/node_modules/@egjs/vue-flicking/dist/flicking.css";
import VueTyperPlugin from 'vue-typer';
import VueApexCharts from 'vue-apexcharts'
import { Icon }  from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip, LIcon, LPopup  } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})



// Global
Vue.use(Flicking);
Vue.use(VueApexCharts)
Vue.use(VueTyperPlugin)
Vue.config.productionTip = false;
Vue.use(Vuetify);
var options = {
  persist: true
}
Vue.use(UUID);
Vue.use(VueSession, options)
library.add(fas);

//COMPONENTES GLOBALES
Vue.component('f-icon', FontAwesomeIcon);
Vue.component("lottie-animation", LottieAnimation);
Vue.component('v-select2', require('./views/components/vselect2').default);
Vue.component("v-loading", require('./components/vloadingoverlay.vue').default);
Vue.component("v-skeleton", require('./components/vskeleton-loader.vue').default);
Vue.component('v-back-btn', require('./components/backbtn.vue').default);
Vue.component('v-menu-options', require('./components/vmenuoptions.vue').default);
Vue.component('v-modal-title', require('./components/vmodaltitle.vue').default);
Vue.component('v-dropzone', require('./components/vdropzone.vue').default);
Vue.component('v-cropper', require('./components/vcropper.vue').default);
Vue.component('v-auto-complete', require('./components/vautocomplete.vue').default);
Vue.component('v-google-maps', require('./components/vgmaps.vue').default);
Vue.component('qrcode-vue',QrcodeVue);
Vue.component('masked-input',InputMask);
Vue.component('v-search-bar',require('./components/searchbar.vue').default);
Vue.component('v-search-input',require('./components/searchbar/RBSearchInputComponent.vue').default);
Vue.component('v-apexchart', VueApexCharts);
Vue.component('r-date-picker',require('./components/vdatepicker.vue').default);
Vue.component('rb-button-back',require('./@core/components/form/button/RBButtonBack.vue').default);
Vue.component('rb-options',require('./@core/components/global/RBOptions.vue').default);
Vue.component('rb-place-map',require('./@core/components/maps/PlaceMap.vue').default);
Vue.component('rb-direction-map',require('./@core/components/maps/DirectionMap.vue').default);

//ERRORS COMPONENTS
Vue.component('rb-exception',require('./@core/components/form/error/HMException.vue').default);

//LEAFLET MAPPER
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-icon', LIcon);
Vue.component('l-popup', LPopup);

//LAYOUTS
Vue.component('default-layout', require('./@core/layouts/default.vue').default);
Vue.component('home-layout', require('./@core/layouts/home.vue').default);
Vue.component('admin-layout', require('./@core/layouts/admin.vue').default);



// Vue.use(vuetify)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY
  },
})



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')


