<template>
  <div>
    <div class="block md:hidden">
        <button  @click="back()" class="flex justify-center items-center bg-white shadow-md rounded-full px-1 py-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </button>
    </div>
    <div @click="back()" class="group bg-gray-200 shadow-sm px-2 py-1 rounded-lg cursor-pointer hover:bg-fucsia-lighter hover:shadow-md hidden md:block">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 text-gray-500 group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="text-sm tracking-wider font-medium text-gray-500 group-hover:text-white">Atras</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        mobile: {
            type: Boolean,
            default: false
        },
        desktop: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        back() {
            this.$router.go(-1);
        }
    }
}
</script>

<style>

</style>