import { HCore } from '../../../config/axios.context'
import { APP_CORE_SIGIN, APP_CORE_USER_SAVE, 
        APP_CORE_GET_USER, APP_CORE_GET_USER_BY_ID,
        APP_CORE_CHECKMAIL, APP_CORE_GETINFORESSETPASSWORD,
        APP_CORE_RESSETPASSWORD, APP_CORE_SEND_EMAIL_LINK,
        APP_CORE_CODEVERIFY, APP_CORE_CLOSE   } from '../../../utils/apiurl';

        
export default  {

    /**
     * Función que permite iniciar sesion con el core
     * @param {any} credentials credenciales de inicio de session en el core
     * @returns 
     */
    async sigIn(credentials) {
        try {
            return await HCore.post(APP_CORE_SIGIN, credentials);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Permite registrar un nuevo usuario
     * @param {any} modelo Modelo
     * @returns 
     */
    async register(modelo) {
        try {
            const url = APP_CORE_USER_SAVE;
            return await HCore.post(url, modelo);
        } catch (error) {
              return error.response.data;
        }
    },   

    /**
     * Función que obtiene la información del usurio con validación de token
     * @param {String} iduser id del usuario
     * @returns 
     */
    async getUser(iduser) {
        try {
            const url = APP_CORE_GET_USER + iduser;
            return await HCore.get(url);
        } catch (error) {
              return error.response.data;
        }
    },
    
    /**
     * Función que permite obtener los datos del usuario sin validación de token
     * @param {*} iduser identificador del usuario
     * @returns 
     */
    async getById(iduser) {
        try {
            const url = APP_CORE_GET_USER_BY_ID + iduser;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },
    
    /**
     * Función que verifica el correo registrado
     * @param {String} param token donde va el id del usuario
     * @returns 
     */
     async checkMail(param) {
        try {
            const url = APP_CORE_CHECKMAIL + param;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que envia la información para realizar el cambio de contraseña
     * @param {String} email correo electronico del usuario
     * @returns 
     */
     async getCodeResetPassword(email) {
        try {
            const url = APP_CORE_GETINFORESSETPASSWORD;
            return await HCore.post(url,email);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que cambia la contraseña del usuario
     * @param {String} model modelo a enviar
     * @returns 
     */
     async ressetPassword(model) {
        try {
            const url = APP_CORE_RESSETPASSWORD;
            return await HCore.put(url,model);
        } catch (error) {
            return error.response.data;
        }
    },    

    /**
     * Permite enviar el correo de confirmacion del correo electronico
     * @param {*} model 
     * @returns 
     */
    async sendLinkConfirm(model) {
        try {
            const url = APP_CORE_SEND_EMAIL_LINK;
            return await HCore.post(url, model);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Permite validar un codigo de recuperacion
     * @param {*} model 
     * @returns 
     */
     async CodeVerify(model) {
        try {
            const url = APP_CORE_CODEVERIFY;
            return await HCore.post(url, model);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Cierra la sesión activa
     * @returns 
     */
     async CloseSession() {
        try {
            const url = APP_CORE_CLOSE;
            return await HCore.put(url,{});
        } catch (error) {
            return error.response.data;
        }
    }
}

