import { HCore } from "./axios.instance"
import httphelper from "./httphelper";
import HttpStatus from '../../utils/http/httpstatus';
import ResultData from '../../models/global/HMResultData.model'


/**
 * Se encarga de proporcionar los servicios para realizar las peticiones al servidor indicado
 * @author Marco Antonio Saraos Hernandez
 * @author Cristian Eduardo Mendez Iglesias
 * @author Humberto Trinidad Dominguez
 */
 const ApiCore = {

    /**
     * Realiza una petición de tipo get a la url inicada
     * @param {String} url url 
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async get(url) { 
        let response = new ResultData();
        try {
            
            let result = await HCore.get(url, httphelper.getCoreHeaders());
            if(result && result.status == HttpStatus.SUCCESS) {
              response = {...result.data, success: true}
            }
        } catch (error) {
          response = {...error.response.data, success: false}
        }
        return response;
    },
    
    /**
     * Realiza una petición de tipo post a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns Object
     */
    async post(url, payload = {} ) { 
        let response = new ResultData();
        try {
            let result = await HCore.post(url, payload, httphelper.getCoreHeaders());
            if(result && result.status == HttpStatus.SUCCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
          response = {...error.response.data, success: false}
        }
        return response;
    },
    
    /**
     * Realiza una petición de tipo put a la url indicando su carga util
     * @param {String} url url a enviar
     * @param {any} payload Carga Util
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     * @returns 
     */
    async put(url, payload = {})  { 
       let response = new ResultData();
        try {
            let result = await HCore.put(url, payload, httphelper.getCoreHeaders());
            if(result && result.status == HttpStatus.SUCCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
          response = {...error.response.data, success: false}
        }
        return response;
    },
    
    /**
     * Permite eliminar registros
     * @param {*} url url a enviar
     * @author Marco Antonio Saraos Hernandez
     * @author Cristian Eduardo Mendez Iglesias
     * @author Humberto Trinidad Dominguez
     */
      async delete(url, payload = {}) {
        let response = new ResultData()
        try {
            let result = await HCore.delete(url,  payload,  httphelper.getCoreHeaders() );
            if(result && result.status == HttpStatus.SUCCESS) {
                response = {...result.data, success: true}
            }
        } catch (error) {
            response = {...error.response.data, success: false}
        }
        return response
      }

}

export default ApiCore;