<template>
  <Select2
    class="text-gray-700 py-1 focus:outline-none"
    :options="options"
    :placeholder="placeholder"
    :settings="{width: '100%', language: 'es'}"
   />
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: function() {return []}
        },
        idkey: {
            type: String,
            default: ''
        },
        textkey: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: "Seleccione un elemento"
        }
    },
    data: function() {
        return {
            options: []
        }
    },
    mounted() {
        this.getOptions();
    },
    methods: {
        getOptions: function() {
            this.options = [];
            for(let item of this.data) {
                this.options.push({id: item[this.idkey], text: item[this.textkey]});
            }
        }
    },
    watch: {
        data: function() {
            if(this.data) {
                this.getOptions();
            }
        }
    }
}
</script>

<style>

</style>