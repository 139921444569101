import auth from '../../store/auth/auth';
import Config from '../config/axios.urlcontext';

export default {

    getToken() {
        const token = auth.state.token;
        let headers = { headers: { "token": `${token}`} };
        return headers;
    },
    getCoreHeaders() {
        const token = auth.state.token;
        let headers = { headers: { "public_key": `${Config.PUBLIC_KEY}`, "token": `${token}`} };
        return headers;
    }    
}