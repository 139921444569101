export default {
    /** RUTA PARA ACCEDER AL CORE */
    APP_CORE_URL: process.env.VUE_APP_CORE_URL_API,

    /** RUTA PARA ACCEDER A REBYU */
    APP_REBYU_URL: process.env.VUE_APP_REBYU_URL_API,

    APP_PRODUCTS_URL: process.env.VUE_APP_PRODUCTS_URL_API,

    /** LLAVE PUBLICA PARA ACCEDER AL CORE  */
    PUBLIC_KEY : process.env.VUE_APP_PUBLIC_KEY
}