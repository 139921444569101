import Swal from 'sweetalert2';

export default {

    /**
     * 
     * @param {string} title titulo del Alert
     * @param {string} text cuerpo del alert
     * @param {string} confirmTextBtn text del boton de confirmación
     * @param {string} cancelTextBtn texto del boton de cancelaxión
     * @param {string} icon icono a mostrar
     * @returns 
     */
    async confirm(title, text, confirmTextBtn, cancelTextBtn, icon){
        return new Promise((resolve, reject) => {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: title,
                text: text,
                icon: icon ? icon :'question',
               // iconHtml: "<i class='fa fa-trash'></i>",
                showCancelButton: true,
                confirmButtonText: confirmTextBtn? confirmTextBtn : 'Aceptar',
                cancelButtonText: cancelTextBtn ? cancelTextBtn : 'Cancelar',
                confirmButtonColor: '#FE2B61',
                cancelButtonColor: '#636f83',
                reverseButtons: true,
                focusConfirm: false,
                focusCancel: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    resolve(result.isConfirmed);
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                    resolve(result.isConfirmed);
                }    
              }).catch((e) => reject(e));


        })
    },

    /**
     * Muestra un notificación tipo toast de exito con un tiempo de duración
     * @param {string} [title] Titulo para el mensaje de toast
     * @param {number} [time] tiempo de duracion del toast - Deafult 3000
     * @param {string} [icon] icono que se mostrará en el toast - Default 'success'
     * @param {string} [position] posicion del toast - Default 'top-end'
     */
     async toast(title = 'Operación realizada correctamente', time = 2000, icon= 'success', position = 'top-end') {
        const Toast = Swal.mixin({
          toast: true,
          position: position,
          showConfirmButton: false,
          timer: time,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: icon,
          title: title
        })
      },

      /**
     * Muestra un notificación tipo toast de fallo con un tiempo de duración
     * @param {string} [title] Titulo para el mensaje de toast
     * @param {number} [time] tiempo de duracion del toast - Deafult 3000
     * @param {string} [icon] icono que se mostrará en el toast - Default 'error'
     * @param {string} [position] posicion del toast - Default 'top-end'
     */
      async failure(title = 'Error en la operación', time = 1500, icon= 'error', position = 'top-end') {
        const Toast = Swal.mixin({
          toast: true,
          position: position,
          showConfirmButton: false,
          timer: time,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: icon,
          title: title
        })
      },

  
  


}