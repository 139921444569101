export default class {
    constructor(
        email,
        password,
        active_session
    ) {
        this.email = email ? email : null,
        this.password = password ? password : null,
        this.active_session = active_session ? active_session : false
    }
}