const PROFILE_PERMISSIONS = {
    CREATE_ENTITY: '0x100' ,
    EDIT_PROFILE: '0x1' ,
    PROFILE_PHOTO: '0x2', 
    ADD_ADDRESS: '0x4', 
}

const HOME_PERMISSIONS = {
    REVIEWS: '0x2',
    HOME: '0x4' 
}


const ENTITY_DETAIL_PERMISSIONS = {
    PREVIEW_ENTITY: '0x1',
    UPDATE_ENTITY: '0x2', 
    HIDDEN_ENTITY: '0x4', 
    DELETE_ENTITY: '0x8', 
    EDIT_PROFILE_PHOTO_ENTITY: '0x10', 
    EDIT_COVER_PHOTO_ENTITY: '0x20', 
}

const ENTITY_DETAIL_INFORMATION = {
    ADD_TAGS: '0x1',
}

const ENTITY_DETAIL_ADDRESS = {
    ADD_ADDRESS_ENTITY: '0x1',
    EDIT_ADDRESS: '0x2',
    CHANGED_ADDRESS: '0x4',
}
const MENU = {
    ADD_PRODUCT: '0x1',
    SHOW_PRODUCT: '0x2',
    EDIT_PRODUCT: '0x4',
    DELETE_PRODUCT: '0x8',
}

export default {
    PROFILE_PERMISSIONS,
    HOME_PERMISSIONS,
    ENTITY_DETAIL_PERMISSIONS,
    ENTITY_DETAIL_INFORMATION,
    ENTITY_DETAIL_ADDRESS,
    MENU
}