import { HCore } from "../../../config/axios.context";
import {APP_CORE_CATCOUNTRY_GET_ALL, APP_CORE_CATSTATE_GET_ALL,
        APP_CORE_CATMUNICIPALITY_GET_ALL, APP_CORE_CATPOSTCODES_GET_ALL } from "../../../utils/apiurl";

export default  {

     /**
     * Funcion que permite obtener la lista de paises
     * @returns 
     */
      async getCountry() {
        try {
            const url = APP_CORE_CATCOUNTRY_GET_ALL;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    
     /**
     * Funcion que permite obtener la lista de estados de un pais
     * @param {string} idcountry identificador de pais
     * @returns 
     */
      async getState(idcountry) {
        try {
            const url = APP_CORE_CATSTATE_GET_ALL + idcountry;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener el listado de municipios
     * @param {String} idstate Id del estado
     * @returns 
     */
    async getMunicipality(idstate) {
        try {
            const url = APP_CORE_CATMUNICIPALITY_GET_ALL + idstate;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite obtener el listado de codigos postales de cada municipio
     * @param {String} idmunicipality Id del municipio
     * @param {String} code codigo del municipio
     * @returns 
     */
    async getPostalCodes(idmunicipality, code) {
        try {
            const url = APP_CORE_CATPOSTCODES_GET_ALL + idmunicipality + "/" + code;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

}
