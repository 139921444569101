<template>
   <div class="mt-1 py-2 w-full lg:w-48 bg-white rounded-md justify-end" >
     <router-link to="/profile">
       <span to="" class="group px-4 py-2 text-gray-600 hover:bg-pink-100 hover:text-fucsia-lighter text-sm flex flex-items-center cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 group-hover:text-fucsia-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          <span class="group-hover:text-fucsia-lighter">Perfil</span>
        </span>
        </router-link>
         <div class="group px-4 py-2 text-gray-600 hover:bg-pink-100 hover:text-fucsia-lighter text-sm flex flex-items-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 group-hover:text-fucsia-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="group-hover:text-fucsia-lighter">Historial</span>
        </div>
         <router-link to="/favorite">
          <div class="group px-4 py-2 text-gray-600 hover:bg-pink-100 hover:text-fucsia-lighter text-sm flex flex-items-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 group-hover:text-fucsia-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
            <span class="group-hover:text-fucsia-lighter">Favoritos</span>
        </div>
         </router-link>
        <hr class="mt-2 mb-2">
         <span to="" class="group px-4 py-2 text-gray-600 hover:bg-pink-100 hover:text-fucsia-lighter text-sm flex flex-items-center cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 group-hover:text-fucsia-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
         </svg>
          <span class="group-hover:text-fucsia-lighter">Configuración</span>
        </span>
         <span @click="logout()" class="group px-4 py-2 text-gray-600 hover:bg-pink-100 hover:text-fucsia-lighter text-sm flex flex-items-center cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 group-hover:text-fucsia-lighter" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>
          <span class="group-hover:text-fucsia-lighter">Salir</span>
        </span>
   </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {

    methods: {
         ...mapActions({
          closeSession: 'auth/closeSession'
        }),
        route(route) {
          this.$router.push(route);
        },
     
        logout: function() {
          this.closeSession();
        }
    },
    computed: {
      ...mapGetters({
        isauth: 'auth/isAuth'
      }),
    }
}
</script>
