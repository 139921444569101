import modules from '../../security/modules/modules';

export default [
    {
        path:'/profile',
        name:'ProfileUser',
        component: () => import('../../views/profileuser/index.vue'),
        meta: {title: 'Perfil - Rebyu', requireAuth: true, layout: 'admin', acm: modules.PROFILE.name},
    },
    {
        path:'/favorite',
        name:'Favorites',
        component: () => import('../../views/favorite/index.vue'),
        meta: {title: 'Favoritos - Rebyu', requireAuth: true, layout: 'admin'},
    }
]