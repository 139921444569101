<template>
  <div class="home h-auto pt-14 mb-10 ">
<!-- BUSCADOR -->
    <div class="w-auto shadow" id="buscador">
        <!-- <v-img class="h-154 w-auto bg-cover bg-center"   :src="imgSearcherURL"> -->
       <div class="sm:h-auto md:h-164 w-auto bg-cover bg-center" :style="`background-image: url('`+imgSearcherURL+`');` ">
        <div class="w-full h-full" >
          <div class="flex justify-center">
            <div class="sm:text-3xl md:text-5xl sm:col-span-1 md:col-span-3 sm:text-center md:text-left  sm:pt-32 md:pt-40 sm:w-auto md:w-144 text-white animate-fade-in-up " style="letter-spacing: 5px; font-family: 'Montserrat', sans-serif;">
              Encuentra todo lo que buscas <span class="text-fucsia-dark font-semibold">en un solo lugar</span>
            </div>
          </div>
          <div class="sm:mt-14 md:pt-24">
            <v-search-bar home />
          </div>

          <div class="sm:col-span-1 md:col-span-3 text-center animate-fade-in-up sm:pt-28 md:pt-36">
            <button  type="button" class="mx-auto w-60 py-2 px-4  ml-2 rounded-lg hover:shadow-md hover:bg-gray-300 bg-white ">
              <span class="text-fucsia-lighter font-thin mr-1">Recomiendame algo</span>
              <v-icon size="22px" color="pink">mdi-shuffle-variant</v-icon>
            </button>
          </div>

        </div>


        <div class="flex justify-center sm:pt-16 md:pt-0">
          <button @click="goToNextDiv('beneficios')" type="button" class="sm:-mb-8 md:-mt-8 z-20  h-16 w-16 py-2 px-2 rounded-full hover:shadow-md hover:bg-pink-red-500 bg-fucsia-dark">
            <v-icon size="36px" color="white" class="animate-pulse">mdi-chevron-down</v-icon>
          </button>
        </div>
       <!-- </v-img> -->
 </div>
    </div>

<!-- BENEFICIOS -->
    <div class="w-auto shadow" style="background-color:#1a2231;"  id="beneficios">


       <div class="sm:h-auto md:h-auto lg:h-auto 2xl:h-164 w-auto bg-auto" :style="`background-image: url('`+imgServicesURL+`');` ">
         <!-- <v-img class="w-auto bg-cover bg-center"           aspect-ratio="1.5" contain :src="imgServicesURL"> -->
            <div class="w-full  border-164 solid border-t-2 border-white"></div>

            <div class="text-center">
              <h6 class="font-semibold sm:text-5xl md:text-7xl text-white animate-fade-in-up pt-24"  style="letter-spacing: 5px; font-family: 'Montserrat', sans-serif;">BENEFICIOS</h6>
            </div>

            <div class="flex justify-center">
              <div class="mt-15 mb-4  w-128">
                <p class="font-thin text-white text-xl text-center">Ayudamos a que tus clientes siempre te encuentren, que tus ventas crezcan y que no te quedes fuera de la evolución tecnológica.</p>
              </div>
            </div>

            <div class="mt-20">

              <div class="grid grid-flow-col sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 sm:grid-rows-4 md:grid-rows-2 lg:grid-rows-2 2xl:grid-rows-1">

                <div class="sm:mb-10">
                  <div class="flex justify-center animate-fade-in-up">
                    <div class="h-80 w-64 bg-gray-300 bg-opacity-25 border border-white rounded-lg backdrop-filter backdrop-blur-sm">    
                        <div class="flex justify-center">
                          <h1 class="text-white text-lg  mt-3">Presencia en internet</h1>
                        </div>
                        <div class="sm:flex sm:justify-center">
                          <div class="h-64 sm:w-60 md:w-64 rounded-md shadow-2xl bg-white sm:ml-0 md:-ml-7 mt-2">
                            <div class="flex sm:justify-center md:justify-start ml-5">
                                <div class="mt-7 pb-5 pr-2  text-center" :style="`background-image: url('`+imgBgCardURL+`');` ">
                                  <div class="m-5" >
                                    <v-img class="bg-cover bg-center" aspect-ratio="1"  :src="imgCard1URL">
                                      </v-img>
                                  </div>
                                </div>
                            </div>  

                            <div class="text-center m-2">
                                <p class="font-semibold text-sm mt-2">
                                    Aumenta el reconocimiento de tu negocio o tu marca, gracias a nuestro poderoso buscador.
                                </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      
                  </div>
                </div>

                <div class="sm:mb-10">
                  <div class="flex justify-center animate-fade-in-up">
                    <div class="h-80 w-64 bg-gray-300 bg-opacity-25 border border-white rounded-lg backdrop-filter backdrop-blur-sm">    
                        <div class="flex justify-center">
                          <h1 class="text-white text-lg  mt-3">Catálogos digitales</h1>
                        </div>
                        <div class="sm:flex sm:justify-center">
                          <div class="h-64 sm:w-60 md:w-64 rounded-md shadow-2xl bg-white sm:ml-0 md:-ml-7  mt-2">
                            <div class="flex sm:justify-center md:justify-start ml-5">
                                <div class="mt-7 pb-5 pr-2  text-center" :style="`background-image: url('`+imgBgCardURL+`');` ">
                                  <div class="m-5" >
                                    <v-img class="bg-cover bg-center" aspect-ratio="1"  :src="imgCard2URL">
                                      </v-img>
                                  </div>
                                </div>
                            </div>  
                            <div class="text-center m-2">
                                <p class="font-semibold text-sm mt-2">
                                    Crea el menú de tú restaurante o el catálogo de tus servicios profesionales para que tus clientes accedan mediante QR.
                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                  </div>
                </div>
                <div class="sm:mb-10">
                  <div class="flex justify-center animate-fade-in-up">
                    <div class="h-80 w-64 bg-gray-300 bg-opacity-25 border border-white rounded-lg backdrop-filter backdrop-blur-sm">    
                        <div class="flex justify-center">
                          <h1 class="text-white text-lg  mt-3">Retroalimentación</h1>
                        </div>
                        <div class="sm:flex sm:justify-center">
                          <div class="h-64 sm:w-60 md:w-64 rounded-md shadow-2xl bg-white sm:ml-0 md:-ml-7  mt-2">
                        
                              <div class="flex sm:justify-center md:justify-start ml-5">
                                  <div class="mt-7 w-28 h-28 text-center" :style="`background-image: url('`+imgBgCardURL+`');` ">
                                    
                                      <v-img class="bg-cover bg-center mt-1 ml-1 w-20" aspect-ratio="1"  :src="imgCard3URL">
                                      </v-img>
                      
                                  </div>
                              </div>  
                           
                            <div class="text-center m-2">
                                <p class="font-semibold text-sm mt-2">                                    
                                  Gracias a nuestro sistema de Rebyus, podras obtener retroalimentación directa de tus clientes y mejorar tu servicio.
                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                  </div>
                </div>

                <div class="sm:mb-10">
                  <div class="flex justify-center animate-fade-in-up">
                    <div class="h-80 w-64 bg-gray-300 bg-opacity-25 border border-white rounded-lg backdrop-filter backdrop-blur-sm">    
                        <div class="flex justify-center">
                          <h1 class="text-white text-lg  mt-3">Mejores ingresos</h1>
                        </div>
                        <div class="sm:flex sm:justify-center">
                          <div class="h-64 sm:w-60 md:w-64 rounded-md shadow-2xl bg-white sm:ml-0 md:-ml-7  mt-2">
                            <div class="flex sm:justify-center md:justify-start ml-5">
                                <div class="mt-7 pb-5 pr-2  text-center" :style="`background-image: url('`+imgBgCardURL+`');` ">
                                  <div class="m-5" >
                                    <v-img class="bg-cover bg-center" aspect-ratio="1"  :src="imgCard4URL">
                                      </v-img>
                                  </div>
                                </div>
                            </div>  
                            <div class="text-center m-2">
                                <p class="font-semibold text-sm mt-1">
                                  Atrae a más clientes ayudando a los usuarios a encontrar tus productos o servicios mediante nuestro buscador.
                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                  </div>
                </div>     

              </div>

            </div>
<!-- </v-img> -->
      </div>
    </div>

<!-- ACERCA DE  -->
    <div class="w-auto shadow pt-1" id="acerca">
      <div class="p-10 bg-card-home">
        <div class="grid md:grid-cols-1 lg:grid-cols-12 gap-5 mt-10 mb-10">

        <div  class="sm:col-start-1 sm:col-end-2 lg:invisible  pt-5 h-72 pr-10 md:mb-5 lg:mb-0">
          <div v-bind:class="{ WindowWidth : 1000 ,'flex':true, 'justify-center -ml-2':true}" >
              <div class="absolute bg-gray-500 mt-10 sm:h-52 md:h-72 sm:w-52 md:w-72 shadow-2xl">
                <img class="sm:h-52 md:h-72 w-auto" :src="img2URL" alt="">
            </div>
              <div class=" absolute z-10  sm:h-52 md:h-72 sm:w-52 md:w-72 ml-24 shadow-2xl">
                <img class="sm:h-52 md:h-72 w-auto" :src="img1URL" alt="">
              </div>
              <div class="absolute border-solid border-8 z-20 border-fucsia-lighter  sm:h-52 md:h-72 sm:w-52 md:w-72 ml-12 mt-5">

              </div>
          </div>
        </div>

          <div class="md:col-start-1 md:col-end-2 lg:col-start-3 lg:col-end-7">
            <div class="text-left text-lg text-blue md:mt-16 lg:mt-0">
              UN PRODUCTO ORIGINAL DE <strong>HEMIA TECHNOLOGIES</strong>
            </div>
            <div class="pt-6">
              <p class="text-left text-3xl font-semibold">
                La primer plataforma Chiapaneca que busca el crecimiento de los negocios locales.
              </p>
            </div>
            <div class="pt-6">
              <p class="text-left text-lg font-thin text-gray-400">
                Como usuario registrate, busca, disfruta y genera reseñas de los lugares que visitas y conoces con Rebyu,
                si eres empresario o tienes un negocio, llega a más personas y
                mantente actualizado en el uso de las nuevas tecnologias.
              </p>
            </div>
            <div class="pt-2">
              <h5 class=" text-blue"><strong>POWERED BY</strong> </h5>
            </div>
            <div class="pt-2 flex justify-center sm:-ml-4 md:-ml-12">
              <div class="sm:h-20 md:h-24 sm:w-20 md:w-40">
                <div class="h-full w-auto bg-cover bg-center" :style="`background-image: url('`+blackhoundURL+`')`"></div>
              </div>
              <div class="sm:h-20 md:h-24 sm:w-36 md:w-40 ">
                <div class="h-full w-auto bg-cover bg-center" :style="`background-image: url('`+hemiaURL+`')`"></div>
              </div>
              <div class="sm:h-20 md:h-24 sm:w-36 md:w-40" >
                <div class="h-full w-auto bg-cover bg-center" :style="`background-image: url('`+urbanoURL+`')`"></div>
              </div>
            </div>
            <div class="pt-5">
              <div class="text-left">
              <button @click="contactanos()" type="button" class="mx-auto w-52 py-2 px-4 bg-gradient-to-r from-fucsia-lighter via-fucsia-dark to-red-900 hover:from-red-900 hover:via-fucsia-dark hover:to-fucsia-lighter rounded-xl text-white">
                <div class="flex">
                  <span class="text-white text-lg">Contactanos</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-7 mt-2" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>

              </button>

              </div>
            </div>
          </div>

          <div class="sm:invisible  lg:visible  lg:col-start-7 lg:col-end-11 xl:col-start-7 xl:col-end-11 "> 

            <!-- <div v-show="WindowWidth >= 900 || WindowWidth == 1024" class="col-start-7 col-end-11"> -->
              <div class="absolute mt-24  h-96 w-96 shadow-2xl">
                <img class="h-96" :src="img2URL" alt="">
              </div>
              <div class="absolute z-10  h-96 w-96 ml-48 shadow-2xl">
                <img class="h-96" :src="img1URL" alt="">
              </div>

              <div class="absolute border-solid border-8 z-20 border-fucsia-lighter  h-imgHome w-imgHome ml-24 mt-5">

              </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>  

<!-- LICENCIAS -->
    <div class="w-auto shadow  bg-blueblack" id="licencias">
       <div class=" w-auto bg-cover bg-center" :style="`background-image: url('`+imgLicencesURL+`');` ">
          <div class="pb-10">
              <div class="text-center ">
                <h6 class="font-semibold sm:text-5xl md:text-7xl text-white animate-fade-in-up pt-20" style="letter-spacing: 5px; font-family: 'Montserrat', sans-serif;">COSTOS</h6>
                <h5 class="mt-15 text-white text-2xl" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif; font-weight: 500;">Paquetes hechos a la medida de tu negocio</h5>
              </div>


                
              <div class="grid grid-cols-1 md:grid-cols-12 gap-2 pt-20 pb-24" v-if="ListaLicencias && ListaLicencias.length > 0">

                <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-5">
                  <div class="flex sm:justify-center md:justify-end">
                    <div class="h-128 w-72 mb-7 rounded-md border-t-4 border-blue-dark shadow-4xl bg-white">

                      <div class="flex justify-center mt-5">
                        <h6 class="font-semibold text-3xl text-blue-dark" style="letter-spacing: 1px;">
                            BÁSICO
                        </h6>
                      </div>   
    
                      <div class=" flex justify-center"> 
                        <div  class="mt-4 mb-4 bg-gray-400 h-1 w-32"></div>
                      </div>

                      <div class="flex justify-center mt-7 ml-1 mr-1 h-64" >    
                        <ul class="text-sm">
                          <li v-for="(detail, index) in ListaLicencias[0].detail" v-bind:key="index" class="mb-4 font-semibold">
                              <v-icon class="-ml-3 " size="20px" :color="detail.contains == 1 ? 'green' : 'red'"> {{ detail.contains == 1 ? 'mdi-check-bold' : 'mdi-close-thick' }} mdi-check-bold</v-icon> {{ detail.description }}
                          </li>                                                           
                        </ul>
                      </div>     

                      <div class="flex justify-center mt-7">
                          <h6 class="font-thin text-5xl text-gray-700 mt-1" style="letter-spacing: 2px;">
                              $
                          </h6>
                          <h6 class="font-semibold text-6xl text-gray-700" style="letter-spacing: 1px;">
                              {{ ListaLicencias[0].price }}
                          </h6>
                          <h6 class="font-semibold text-xs text-gray-700 mt-7" style="letter-spacing: 1px;">
                              /m
                          </h6>  
                      </div>  

                      <div class="flex justify-center mt-7">   
                        <button @click="goToPrices(ListaLicencias[0].id)" type="button" class="mx-auto w-52 py-2 px-4 bg-gradient-to-r from-blue-lighter via-blue to-blue-dark hover:from-blue-dark hover:via-blue hover:to-blue-lighter rounded-lg text-white">
                            <span class="text-white text-lg">SELECCIONAR</span>
                        </button>
                      </div>    

                    </div>
                  </div>

                </div>

                <div class="col-start-1 col-end-2 md:col-start-5 md:col-end-9">
                  <div class="flex justify-center ">
                    <div class="h-130 w-cardPrice mb-7 rounded-md  shadow-5xl bg-white -mt-4">

                      <div class="flex justify-center mt-5">
                        <h6 class="font-semibold text-3xl text-fucsia-lighter" style="letter-spacing: 1px;">
                            PREMIUM
                        </h6>
                      </div>   
    
                      <div class=" flex justify-center"> 
                        <div  class="mt-4 mb-4 bg-gray-400 h-1 w-32"></div>
                      </div>

                      <div class="flex justify-center mt-8 ml-1 mr-1 h-64">    
                        <ul class="text-sm">
                          <li v-for="(detail, index) in ListaLicencias[1].detail" v-bind:key="index" class="mb-4 font-semibold">
                              <v-icon class="-ml-3 " size="20px" :color="detail.contains == 1 ? 'green' : 'red'"> {{ detail.contains == 1 ? 'mdi-check-bold' : 'mdi-close-thick' }} mdi-check-bold</v-icon> {{ detail.description }}
                          </li>                                                         
                        </ul>
                      </div>     

                      <div class="flex justify-center mt-8">
                          <h6 class="font-thin text-5xl text-gray-700 mt-1" style="letter-spacing: 2px;">
                              $
                          </h6>
                          <h6 class="font-semibold text-6xl text-gray-700" style="letter-spacing: 1px;">
                              {{ ListaLicencias[1].price }}
                          </h6>
                          <h6 class="font-semibold text-xs text-gray-700 mt-7" style="letter-spacing: 1px;">
                              /m
                          </h6>  
                      </div>  

                      <div class="flex justify-center mt-7">   
                        <button @click="goToPrices(ListaLicencias[1].id)" class="mx-auto w-52 py-2 px-4 bg-gradient-to-r from-fucsia-lighter via-fucsia-dark to-pink-red hover:from-pink-red hover:via-fucsia-dark hover:to-fucsia-lighter rounded-lg text-white">
                            <span class="text-white text-lg">SELECCIONAR</span>
                        </button>
                      </div>    
                                              
                    </div>
                  </div>
                </div>

                <div class="col-start-1 col-end-2 md:col-start-9 md:col-end-13">
                  <div class="flex sm:justify-center md:justify-start">
                    <div class="h-128 w-72 mb-7 rounded-md border-t-4 border-menu-green shadow-lg hover:shadow-xl bg-white">
                      <div class="flex justify-center mt-5">
                        <h6 class="font-semibold text-3xl text-blue-dark" style="letter-spacing: 1px;">
                            AVANZADO
                        </h6>
                      </div>   
    
                      <div class=" flex justify-center"> 
                        <div  class="mt-4 mb-4 bg-gray-400 h-1 w-32"></div>
                      </div>

                      <div class="flex justify-center mt-7 ml-1 mr-1 h-64">    
                        <ul class="text-sm">
                          <li v-for="(detail, index) in ListaLicencias[2].detail" v-bind:key="index" class="mb-4 font-semibold">
                              <v-icon class="-ml-3 " size="20px" :color="detail.contains == 1 ? 'green' : 'red'"> {{ detail.contains == 1 ? 'mdi-check-bold' : 'mdi-close-thick' }} mdi-check-bold</v-icon> {{ detail.description }}
                          </li>                                                       
                        </ul>
                      </div>     

                      <div class="flex justify-center mt-7">
                          <h6 class="font-thin text-5xl text-gray-700 mt-1" style="letter-spacing: 2px;">
                              $
                          </h6>
                          <h6 class="font-semibold text-6xl text-gray-700" style="letter-spacing: 1px;">
                              {{ ListaLicencias[2].price }}
                          </h6>
                          <h6 class="font-semibold text-xs text-gray-700 mt-7" style="letter-spacing: 1px;">
                              /m
                          </h6>  
                      </div>  

                      <div class="flex justify-center mt-7">   
                        <button @click="goToPrices(ListaLicencias[2].id)" type="button" class="mx-auto w-52 py-2 px-4 bg-gradient-to-r from-greenH-lighter via-greenH to-greenH-dark hover:from-greenH-dark hover:via-greenH hover:to-greenH-lighter rounded-lg text-white">
                            <span class="text-white text-lg">SELECCIONAR</span>
                        </button>
                      </div>    
                                                              
                    </div>
                  </div>
                </div>
                            
              </div>
          </div>
      </div>
    </div>

<!-- NUEVOS SOCIOS -->
    <div class="w-auto h-full shadow" id="socios" >

      <div class="flex justify-center"> 
          <div class="sm:h-28 md:h-32 w-full rounded-lg bg-fucsia-dark -mt-14 sm:ml-5 sm:mr-5 md:ml-14 md:mr-14 shadow-lg">
            <div class=" flex space-x-2">
              <div class="flex-1">
                  <div class="flex justify-center">         
                    <div class="mt-4" >
                      <div class="flex justify-center">
                        <v-icon class="" size="44" color="white">mdi-map-search</v-icon>
                      </div> 
                      <h5 class="sm:text-xl md:text-5xl text-white" style="letter-spacing: 3px; font-family: 'Montserrat', sans-serif;">
                        Busca
                      </h5>
                    </div>
                  </div>
              </div>

              <div class="flex-1">
                  <div class="flex justify-center">         
                    <div class="mt-4" >
                      <div class="flex justify-center">
                        <v-icon class="" size="44" color="white">mdi-pasta</v-icon>
                      </div> 
                      <h5 class="sm:text-xl md:text-5xl text-white" style="letter-spacing: 3px; font-family: 'Montserrat', sans-serif;">
                        Consume
                      </h5>
                    </div>
                  </div>
              </div>

              <div class="flex-1">
                  <div class="flex justify-center">         
                    <div class="mt-4" >
                      <div class="flex justify-center">
                        <v-icon class="" size="44" color="white">mdi-message-draw</v-icon>
                      </div> 
                      <h5 class="sm:text-xl md:text-5xl text-white" style="letter-spacing: 3px; font-family: 'Montserrat', sans-serif;">
                        Reseña
                      </h5>
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </div>

      <div class="text-center">
        <h6 class="font-semibold sm:text-5xl md:text-7xl text-blueblack animate-fade-in-up pt-28"  style="letter-spacing: 5px; font-family: 'Montserrat', sans-serif;">VISITA REBYU</h6>
        <h5 class="mt-10 text-blueblack text-xl" style="letter-spacing: 2px; font-family: 'Montserrat', sans-serif; font-weight: 510;">Lo mejor de tu ciudad EN UN SOLO LUGAR</h5>
      </div>
      
      <div class="mt-10 w-auto h-128 bg-cover" :style="`background-image: url('`+imgBgEntidadesURL+`')`">  
        <div class="flex justify-center w-full h-full m-2">

          <!-- <v-img max-height="700" max-width="350" :src="imgBgPhoneMockUpURL"> -->
          <Flicking class="bg-opacity-0 pb-20" :options="options" :plugins="plugins" :hideBeforeInit="true" :firstPanelSize="'500px'">
            <div v-for="(element,index) in ListaUltimos" :key="index" class="m-5">

              <div class="shadow-2xl h-100 w-cardPrice  rounded-lg bg-white" :id="index">

                <div class="h-40 w-auto bg-cover bg-center rounded-t-lg" :style="`background-image: url('`+element.CoverPhoto+`');` "></div>

                <div class="text-center mb-4">
                  <v-avatar class="-mt-12" size="90">
                    <v-img class="border-2 border-white" :src="element.ProfilePicture">
                    </v-img>
                  </v-avatar>
                </div>

                <div class="justify-center text-center  mb-2">
                    <h1 class="font-weight-bold text-lg text-black">
                        {{element.name}}
                    </h1>
                </div>

                <div class="text-center">
                  <v-rating background-color="grey" color="warning" icon-label="custom icon label text {0} of {1}" hover length="5" size="20" :value="3" readonly></v-rating>
                </div>

                <div class=" h-20 w-64 ml-2">
                  <div class="text-center">
                    <p class="text-xs ">{{element.description | truncate(209) }}</p>
                  </div>
                </div>

                <div class="mt-5 text-center">
                  <button @click="goTo(element.tagname)" type="button" class="mx-auto w-48 py-2 px-4 bg-gradient-to-r from-fucsia-lighter via-fucsia-dark to-red-900 hover:from-red-900 hover:via-fucsia-dark hover:to-fucsia-lighter rounded-xl text-white">
                      <span class="text-white text-base">Ir al lugar</span>
                  </button>
                </div>

              </div>
            
            </div>

            <span slot="viewport" class="flicking-arrow-prev bg-black bg-opacity-20 rounded-full"></span>
            <span slot="viewport" class="flicking-arrow-next bg-black bg-opacity-20  rounded-full"></span>
          </Flicking>

        </div>
      </div> 
    </div>

<!-- COMUNIDAD -->
    <div class="w-auto h-164 bg-blackblue2 shadow" id="comunidad">
      <div class="pt-32 md:ml-36 h-164 bg-cover " :style="`background-image: url('`+imgBgComunityForm2dURL+`');`">
      <div class="grid sm:grid-cols-1 md:grid-cols-12">
          <div class="sm:col-start-1 sm:col-end-2 lg:col-start-1 lg:col-end-6">
              
                <div class="sm:flex sm:justify-center md:flex md:justify-center ">
                  <div class="absolute sm:mt-96 md:mt-24 lg:mt-24  sm:h-72 md:h-96 lg:h-96 xl:h-120 w-auto">
                    <img class="sm:h-72 md:h-96 lg:h-96 xl:h-120 lg:-ml-10" :src="imgBgCommunityURL" alt="">
                  </div>
                </div>
              
          </div>
          <div class="sm:col-start-1 sm:col-end-2 md:col-start-6 md:col-end-13">
            <div class="w-full">
              <div class="h-80 text-center bg-cover md:bg-center sm:-mt-48 md:-mt-16" :style="`background-image: url('`+imgBgElementsdURL+`');`">
                <div class="sm:pt-36 md:pt-52">
                    <h5 class="text-white text-5xl  font-semibold" style="letter-spacing: 3px;">Únete a la comunidad</h5>
                </div>
              </div>
                
              <div class="sm:mt-2 lg:mt-8 flex justify-center">
                <div class="text-center w-141">
                  <p class="text-white sm:text-base md:text-xl" style="letter-spacing: 3px;">
                    Descubre una nueva experiencia de busqueda, encuentra lo que necesitas de forma fácil y rápida.
                    Consigue nuevos clientes potenciales, exponiendo tu menú de alimentos o servicios, admistra tus ventas, y gestiona reservaciones.
                  </p>
                </div>
              </div>
            </div>

            <div class="flex justify-center sm:mt-0 md:mt-5">
                  <button @click="goTo('login')" type="button" class="mx-auto w-52 py-2 px-4 bg-white hover:bg-gray-200 rounded-xl ">
                    <div class="flex justify-center">
                      <span class="text-fucsia-lighter text-lg">Registrarme</span>
                    </div>

                  </button>
            </div>
          </div>
      </div>

      
      </div>
    </div>

  </div>
</template>

<script>

import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import { LicenceService } from '../../services/HSuite/Context/hsuite-core-context';
import ToastMixin from '../../mixins/vue/toastmixin.js';
import {validationMixin} from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Search from '../../models/entity/search.model';
import { Arrow,AutoPlay  } from "@egjs/flicking-plugins";
import "/node_modules/@egjs/flicking-plugins/dist/arrow.css";
import searchEngineService from '../../services/searchengine/searchengine.service';

export default {
  mixins: [ToastMixin, validationMixin],
  name: 'IndexHome',
  components: {

  },
  data() {
    return {
      cla:'transform z-10 ease-in-out duration-700 h-72 hover:h-full cursor-pointer',
      loading: false,
      loadingsearch: false,
      isopensearch: false,
      model: null,
      selection: null,
      latitude:0,
      longitud:0,
      geoLocationPosition:{},
      blackhoundURL:require('@/assets/home/g1.svg'),
      hemiaURL:require('@/assets/home/g2.svg'),
      urbanoURL:require('@/assets/home/g3.svg'),
      img1URL:require('@/assets/home/1.jpg'),
      img2URL:require('@/assets/home/2.jpg'),
      imgSearcherURL:require('@/assets/home/bgBuscador.jpg'),
      imgServicesURL:require('@/assets/home/bgBeneficios.png'),
      imgLicencesURL:require('@/assets/home/bgCostos.png'),
      imgBgCardURL:require('@/assets/home/bgshapeHomeCard.png'),
      imgBgCommunityURL:require('@/assets/home/communityilustration.png'),
      imgBgElementsdURL:require('@/assets/home/elementscomunity.png'),
      imgBgPhoneMockUpURL:require('@/assets/home/phonemockup.png'),
      imgBgEntidadesURL:require('@/assets/home/bgfondocards.png'),
      imgBgComunityForm2dURL:require('@/assets/home/bgComunityForm2.png'),
      imgCard1URL:require('@/assets/home/cardInternet.svg'),
      imgCard2URL:require('@/assets/home/cardCatalogo.svg'),
      imgCard3URL:require('@/assets/home/cardRetroalimentacion.svg'),
      imgCard4URL:require('@/assets/home/cardIngresos.svg'),
      ListaUltimos : {},
      WindowHeight:'',
      WindowWidth:'',
      busqueda:'',
      search_data: new Search(),
      plugins: [new Arrow(),new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: true })],
      options:{ circular: true,align: "center",autoResize: true},
      searchsuggestiontags:[],
      searchautocompletedata: [],
      arrowCounter: -1,
      ListaLicencias:null
    }
  },

  validations :{
      search_data: {
        search: {
          required
        }
      }

  },
  mounted()
  {
    this.init();
    window.onresize = () => {

        this.WindowWidth = document.documentElement.clientWidth;
        this.WindowHeight = document.documentElement.clientHeight;
     };
  },
  created() {
        this.winWidth();  
  },
  methods:{


    init: async function() {
      await this.getLicenses();
      await this.getEntitys(this.name);
      await this.getSuggestionsTags();
    },

    getindexClass(value,option){

      if(value == 1&&option==1) return 1;
      if(value == 2&&option==1) return 5;
      if(value == 3&&option==1) return 9;

      if(value == 1&&option==2) return 5;
      if(value == 2&&option==2) return 9;
      if(value == 3&&option==2) return 13;
    },

    getstarClass(value){
      
      if(value == 1) return 'flex sm:justify-center md:justify-end';
      if(value == 2) return 'flex justify-center';
      if(value == 3) return 'flex sm:justify-center md:justify-start';
    },

    prevCard: function(){
        this.$refs.flicking.prev();
    },

    nextCard: function(){
      this.$refs.flicking.next();
    },

    goTo: function (ruta){
        location.href = '/' + ruta

    },

    goToPrices: function (_id){
       this.$router.push({ name : 'Prices', params : { id : _id }});
    },

    contactanos: function (){
       location.href = 'https://api.whatsapp.com/send?phone=+529612010272&text=Hola,%20Me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20de%20Rebyu'
    },

    goToNextDiv(iddiv)
    {
      document.getElementById(iddiv).scrollIntoView({ behavior: 'smooth' });
    },

    winWidth: function () {

      this.WindowWidth = screen.width;
      this.WindowHeight = screen.height;

    },

    getLicenses:async function() {

      let response = await LicenceService.getLicenses();
      if(response.success) {
          this.ListaLicencias = response.data;
          
      }else {
          this.failure(response.message);
      }
    },

    getEntitys: async function() {
      let response = await EntityService.getByRows(20);
      if(response.success) {
          this.ListaUltimos = response.data;
          // this.ListaUltimos.push(this.ListaUltimos[0] ? this.ListaUltimos[0]: null, this.ListaUltimos[3])
          
      }else {
          this.failure(response.message);
      }
    },

    getSuggestionsTags: async function() {
      let response = await searchEngineService.getSuggestionTags();
      if(response.success) {
        this.searchsuggestiontags = response.data;
      }
    },
    
    search: async function() {
      if(this.search_data.search.includes('@')){
          var new_ruta = this.search_data.search.replace('@','')
          location.href = '/' + new_ruta
          return;
      }

      this.$v.$touch();
      if(!this.$v.$anyError) {
        this.loadingsearch = true;
        let response = await searchEngineService.autocomplete(this.search_data.search, '0');
        this.loadingsearch = false;
        if(response.success) {
          this.searchautocompletedata = response.data;
        }
      }

    },

    handlesearch: function(isbtn = false) {
      this.$v.$touch();
      if(!this.$v.$anyError) {
        if(isbtn) {
          window.location.href = '/searcher?busqueda=' + this.search_data.search;
        }else {
          if(this.arrowCounter == -1) {
             window.location.href = '/searcher?busqueda=' + this.search_data.search;
          }else {
             let selected =  this.searchautocompletedata[this.arrowCounter];
              window.location.href = selected.url;
          }
        }
      }
    },

    handlekeydown: function() {
      if (this.arrowCounter < this.searchautocompletedata.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    
    handlekeyup: function() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

  },

  computed: {

    suggestiontags: function() {
      return [...new Set(this.searchsuggestiontags.map(item => item.description))]
    }
  },
  watch: {
    'search_data.search' : function() {
      if(this.search_data.search.length < 4) {
        this.searchautocompletedata = [];
      }

      if(this.search_data.search.length >= 4) {
          this.isopensearch = true;
          this.search();
      }else {
         this.isopensearch = false;
      }
    }
  }
}
</script>

<style scoped>

* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
}
      
.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
.flip-horizontal-bottom {
  animation: flip-horizontal-bottom 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

  ::v-deep .typed {
    color: #fff !important;
  }


@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-360deg);
  }
}

</style>