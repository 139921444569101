import decode from 'jwt-decode'
//import urlApis from '../Api.config'

const USUARIO_INFO_KEY = 'UsuarioInfo';

export function ObtenerSesion() {

    let usuario = JSON.parse(localStorage.getItem(USUARIO_INFO_KEY));

    if (usuario != null) {
        return usuario;
    }
    return null;

}
export function ObtenerIdUser() {

    let usuario = JSON.parse(localStorage.getItem(USUARIO_INFO_KEY));

    if (usuario != null && usuario.idUser) {
        return usuario.idUser;
    }
    return null;

}

// export function LimpiarToken() {
//     urlApis.serverAuthorizeApi().defaults.headers.common['Authorization'] = '';
//     urlApis.catalogosApi().defaults.headers.common['Authorization'] = '';
//     urlApis.rhApi().defaults.headers.common['Authorization'] = '';
//     urlApis.nominasApi().defaults.headers.common['Authorization'] = '';
//     urlApis.signalHubApi().defaults.headers.common['Authorization'] = '';
//     urlApis.serverSecurityApi().defaults.headers.common['Authorization'] = '';
//     localStorage.removeItem(USUARIO_INFO_KEY);
// }

export function UsuarioEstaLogeado() {

    let authToken = ObtenerSesion();
    return !!authToken && !TokenActivo(authToken.token)
}

export function getUserInfo() {
    if (UsuarioEstaLogeado()) {
        return decode(ObtenerSesion())
    }
}

export function ObtenerFechaExpiracion(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

export function TokenActivo(token) {
    let fechaExpiracion = ObtenerFechaExpiracion(token);
    
    return fechaExpiracion > new Date();
}

export function TokenActivoMinutos(token) {
    let fechaExpiracion = ObtenerFechaExpiracion(token);

    return fechaExpiracion > new Date();
}

export function MinutosTokenRestante(token) {

    let fechaExpiracion = ObtenerFechaExpiracion(token);
    let fechaActual = new Date();
    var diferencia = 0;
    if(!fechaExpiracion){
        let fechaActual = new Date();
        fechaActual.setDate(fechaActual.getDate() + 5);
        fechaExpiracion = fechaActual;
    }
    diferencia = (fechaExpiracion.getTime() -  fechaActual.getTime()) / 1000;
    diferencia = diferencia / 60;
    
    return diferencia;
}