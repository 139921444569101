import { HCore } from '../../../config/axios.context'
import { APP_CORE_PERSON_GET_BY_USER, APP_CORE_PERSON_UPDATE } from '../../../utils/apiurl';


export default  {

    /**
     * Permite obtener los datos de usuario por ID de usuario
     * @returns 
     */
    async getByUser() {
        try {
            const url = APP_CORE_PERSON_GET_BY_USER;
            return await HCore.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Actualiza la información personal del usuario
     * @param {*} id ID de la persona
     * @param {*} data Datos de la persona
     * @returns 
     */
    async update(id, data) {
        try {
            const url = APP_CORE_PERSON_UPDATE + id;
            return await HCore.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

}
