import Vue from 'vue';

export default Vue.extend({
    name: 'searcheable',

    props: {
        home: Boolean,
        search: Boolean
    },

    computed: {
        searcheableClass() {
            return {
                'md:w-144 back-drop-2 bg-gray-300  mx-5': this.home,
                'w-full bg-gray-200 shadow-sm': this.search,
            }
        },
        searcheableInputClass() {
            return {
                'bg-gray-600 bg-opacity-80 text-white  focus:text-white home-input-text': this.home,
                'bg-white text-gray-400 ': this.search
            }
        },
        searcheableTextClass() {
            return {
                'text-white': this.home,
                'text-gray-400': this.search
            }
        },
        searcheableButtonClass() {
            return {
                'bg-gray-600 hover:bg-gray-300': this.home,
                'bg-gray-400 hover:bg-gray-300': this.search,
            }
        }
    }
})