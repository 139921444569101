import ApiRebyu from '../../../config/ApiRebyu';
import { APP_REBYU_PROFILE_REGISTER, APP_REBYU_PROFILE_GET_BY_ID_USER  } from '../../../utils/apiurl';

export default  {

    /**
     * Función que se encarga de guardar los datos del perfil del usuario
     * @param {any} data Modelo de datos del perfil
     * @returns 
     */
    async save(data) {
        try {
            const url = APP_REBYU_PROFILE_REGISTER;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que obtiene los datos del perfil del usuario
     * @returns Modelo de datos del perfil
     */
    async getByIdUser() {
        try {
            const url = APP_REBYU_PROFILE_GET_BY_ID_USER;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },    


}
