
    //HMCORE//
    export const APP_CORE_SIGIN = '/api/auth/sigin';
    export const APP_CORE_CLOSE = '/api/auth/close';
    export const APP_CORE_CAT_SEX_GET_ALL = '/api/auth/sigin';
    export const APP_CORE_GET_USER = '/api/user/obtener/';
    export const APP_CORE_USER_SAVE = '/api/user/register';
    export const APP_CORE_GET_USER_BY_ID = '/api/user/get/';
    export const APP_CORE_CHECKMAIL = '/api/user/checkMail/';
    export const APP_CORE_GETINFORESSETPASSWORD = '/api/user/getCodeResetPassword';
    export const APP_CORE_RESSETPASSWORD = '/api/user/ressetPassword';
    export const APP_CORE_SEND_EMAIL_LINK = '/api/user/sendLinkConfirm';
    export const APP_CORE_CODEVERIFY = '/api/user/codeVerify';
    export const APP_CORE_ROLE_GET_BY_SYSTEM = '/api/role/get/system';
    export const APP_CATSEX_GET_ALL = '/api/catsex/getAll';
    export const APP_CORE_ADDRESS_REGISTER = '/api/address/register';
    export const APP_CORE_LICENSES_GET_BY_SYSTEM = '/api/licenses/getBySystem';
    export const APP_CORE_LICENSES_REGISTER = '/api/licenses/licenseRegister';
    export const APP_CORE_LICENSES_GET_BY_ID = '/api/licenses/get/';
    export const APP_CORE_CATCOUNTRY_GET_ALL = '/api/location/country/getall';
    export const APP_CORE_CATSTATE_GET_ALL = '/api/location/state/getall/';
    export const APP_CORE_CATMUNICIPALITY_GET_ALL = '/api/location/municipality/getall/';
    export const APP_CORE_CATPOSTCODES_GET_ALL = '/api/location/postalcode/getall/';
    export const APP_CORE_PERSON_GET_BY_USER = '/api/person';
    export const APP_CORE_PERSON_UPDATE = '/api/person/';
    export const APP_CORE_ADDRESS_BY_USER = '/api/address/user';
    export const APP_CORE_ADDRESS_BY_ID = '/api/address/get/';
    export const APP_CORE_ADDRESS_SAVE = '/api/address/save';
    export const APP_CORE_ADDRESS_UPDATE = '/api/address/update/';
    export const APP_CORE_ADDRESS_STATUS = '/api/address/status/';

    //REBU//
    export const APP_REBYU_ENTITY_SAVE = '/api/entity/save';
    export const APP_REBYU_ENTITY_UPDATE = '/api/entity/update/';
    export const APP_REBYU_ENTITY_GET_BY_ID_USER = '/api/entity/get/user';
    export const APP_REBYU_ENTITY_GET_INFO = '/api/entity/getinfo/';
    export const APP_REBYU_ENTITY_GET_INFO_TAGNAME = '/api/entity/getstore/';
    export const APP_REBYU_ENTITY_GET_ALL = '/api/entity/getAll/';
    export const APP_REBYU_ENTITY_SEARCH = '/api/entity/search';
    export const APP_REBYU_ENTITY_REGISTER = '/api/entity/register';
    export const APP_REBYU_PROFILE_REGISTER = '/api/profile/register';
    export const APP_REBYU_PROFILE_GET_BY_ID_USER = '/api/profile/get/user';
    export const APP_REBYU_PROFILE_PICTURE_SAVE = '/api/profiluserpicture/save';
    export const APP_REBYU_ENTITY_CONFIG_GET = '/api/entityconfig/get/entity/';
    export const APP_REBYU_ENTITY_CONFIG_SAVE = '/api/entityconfig/save';
    export const APP_REBYU_ENTITY_TAGNAME_VALIDATOR = '/api/entity/tagname/validate';
    export const APP_REBYU_ENTITY_GET_STORE_CATEGORIES = '/api/entity/store/categories/';
    export const APP_REBYU_ENTITY_STATUS = '/api/entity/status/';
    export const APP_REBYU_ENTITY_GET_BY_ROWS = '/api/entity/getrows/';
    export const APP_REBYU_REVIEW_SAVE = '/api/review/save';
    export const APP_REBYU_REVIEW_GET_BY_ID_ENTITY = '/api/review/get/entity/';
    export const APP_REBYU_SHARED_LINK_SAVE = '/api/links/save';
    export const APP_REBYU_SHARED_LINK_GET_BY_ID_ENTITY = '/api/links/get/entity/';
    export const APP_REBYU_SHARED_LINK_STATUS = '/api/links/status/';
    export const APP_REBYU_SHARED_GET_BY_CODE = '/api/links/shared/';
    export const APP_REBYU_ENTITY_FOLLOWER_SAVE = '/api/follower/save';
    export const APP_REBYU_ENTITY_COVER_SAVE = '/api/entity/saveCoverPicture';
    export const APP_REBYU_ENTITY_PROFILE_SAVE = '/api/entity/saveProfilePicture';
    export const APP_REBYU_ENTITY_FOLLOWER_BY_USER = '/api/follower/favorite';
    export const APP_REBYU_SEARCH_ENGINE_GET_SUGGESTION_TAGS = '/api/search/get/suggestions/tags/';
    export const APP_REBYU_SEARCH_ENGINE_AUTOCOMPLETE = '/api/search/autocomplete';
    export const APP_REBYU_BUSINESS_LINE_GET = '/api/businessline/get/line';
    export const APP_REBYU_BUSINESS_LINE_VALUE_GET = '/api/businessline/get/line/value/';
    export const APP_REBYU_ENTITY_ADDRESS_SAVE = '/api/addressEntity/save';
    export const APP_REBYU_ENTITY_ADDRESS_GET_BY_ID_ENTITY = '/api/addressEntity/get/entity/';
    export const APP_REBYU_ENTITY_ADDRESS_GET_ALL_BY_ID_ENTITY = '/api/addressEntity/get/all/entity/';
    export const APP_REBYU_ENTITY_ADDRESS_GET_BY_ID = '/api/addressEntity/get/';
    export const APP_REBYU_ENTITY_ADDRESS_UPDATE = '/api/addressEntity/update/';
    export const APP_REBYU_ENTITY_ADDRESS_SET = '/api/addressEntity/set/';
    export const APP_REBYU_TAGS_GET_ALL = '/api/catTags/getAll';
    export const APP_REBYU_ENTITY_TAGS_SAVE = '/api/entity/saveTags';
    export const APP_REBYU_ENTITY_TAGS_GET_ENTITY = '/api/entity/get/tags/';
    export const APP_REBYU_SERVICES_BY_ENTITY = '/api/service/store/';

    //PRODUCTOS//
    export const APP_PRODUCTS_CATEGORY_SAVE = '/api/category/save';
    export const APP_PRODUCTS_CATEGORY_UPDATE = '/api/category/update/';
    export const APP_PRODUCTS_ENTITY_TAX_GET_ALL = '/api/entitytax/getAll/';
    export const APP_PRODUCTS_ENTITY_TAX_SET = '/api/entitytax/set/tax/';
    export const APP_PRODUCTS_CATEGORY_GET_BY_ID = '/api/category/get/entity/';
    export const APP_PRODUCTS_CATEGORY_STATUS = '/api/category/status/';
    export const APP_MENU_PRODUCT_CATEGORY_SAVE = '/api/menu/save/category';
    export const APP_MENU_PRODUCT_CATEGORY_GET_ALL = '/api/menu/get/category/';
    export const APP_MENU_PRODUCT_CATEGORY_GET_ALL_STORE = '/api/menu/get/category/store/';
    export const APP_MENU_PRODUCT_COMPLEMENT_SUMMARY = '/api/menu/complements/';
    export const APP_MENU_PRODUCT_STATUS = '/api/menu/status/';
    export const APP_MENU_PRODUCT_GET_BY_ID = '/api/menu/get/';
    export const APP_PRODUCTS_PRODUCT_TAXES_GET_BY_ID_PRODUCT = '/api/taxes/get/product/';
    export const APP_MENU_PRODUCT_CATEGORY_UPDATE = '/api/menu/update/category/';
    export const APP_PRODUCTS_SERVICE_GET_ALL = '/api/service/get/category/';
    export const APP_PRODUCTS_SERVICE_SAVE = '/api/service/save';
    export const APP_PRODUCTS_SERVICE_UPDATE = '/api/service/update/';
    export const APP_PRODUCTS_SERVICE_STATUS = '/api/service/status/';
    export const APP_PRODUCTS_SERVICE_GET_BY_ID = '/api/service/get/';
    export const APP_PRODUCTS_SERVICE_DETAIL_SAVE = '/api/servicedetail/save';
    export const APP_PRODUCTS_SERVICE_DETAIL_GET_BY_SERVICE = '/api/servicedetail/get/service/';
    export const APP_PRODUCTS_SERVICE_DETAIL_STATUS = '/api/servicedetail/status/';
    export const APP_PRODUCTS_SERVICE_DETAIL_UPDATE = '/api/servicedetail/update/';
    export const APP_PRODUCTS_SERVICE_DETAIL_BY_ID = '/api/servicedetail/get/';
    export const APP_PRODUCTS_TOPPING_SAVE = '/api/topping/save';
    export const APP_PRODUCTS_TOPPING_UPDATE = '/api/topping/update/';
    export const APP_PRODUCTS_TOPPING_STATUS = '/api/topping/status/';
    export const APP_PRODUCTS_TOPPING_GET_BY_PRODUCT = '/api/topping/get/product/';
    export const APP_PRODUCTS_TOPPING_GET_BY_ID_PRODUCT = '/api/topping/get/id/product/';
    export const APP_PRODUCTS_TOPPING_VALUE_SAVE = '/api/toppingvalue/save';
    export const APP_PRODUCTS_TOPPING_VALUE_UPDATE = '/api/toppingvalue/update/';
    export const APP_PRODUCTS_TOPPING_VALUE_GET_BY_TOPPING = '/api/toppingvalue/get/topping/';
    export const APP_PRODUCTS_TOPPING_VALUE_GET_BY_ID = '/api/toppingvalue/get/';
    export const APP_PRODUCTS_TOPPING_VALUE_STATUS = '/api/toppingvalue/status/';
    export const APP_PRODUCTS_VARIANT_SAVE = '/api/variant/save';
    export const APP_PRODUCTS_VARIANT_UPDATE = '/api/variant/update/';
    export const APP_PRODUCTS_VARIANT_STATUS = '/api/variant/status/';
    export const APP_PRODUCTS_VARIANT_GET_BY_PRODUCT = '/api/variant/get/product/';
    export const APP_PRODUCTS_VARIANT_GET_BY_ID = '/api/variant/get/';
    export const APP_PRODUCTS_VARIANT_VALUE_SAVE = '/api/variantvalue/save';
    export const APP_PRODUCTS_VARIANT_VALUE_UPDATE = '/api/variantvalue/update/';
    export const APP_PRODUCTS_VARIANT_VALUE_GET_BY_VARIANT = '/api/variantvalue/get/variant/';
    export const APP_PRODUCTS_VARIANT_VALUE_GET_BY_ID = '/api/variantvalue/get/';
    export const APP_PRODUCTS_VARIANT_VALUE_STATUS = '/api/variantvalue/status/';
    export const APP_PRODUCTS_CAT_HOURS_GET_ALL = '/api/cat/hours/get/all';
    export const APP_PRODUCTS_SCHEDULE_SAVE = '/api/schedule/save';
    export const APP_PRODUCTS_SCHEDULE_SAVE_TAG = '/api/schedule/savetag';
    export const APP_PRODUCTS_SCHEDULE_UPDATE = '/api/schedule/update/';
    export const APP_PRODUCTS_SCHEDULE_GET_BY_ENTITY = '/api/schedule/get/entity/';
    export const APP_GETCSCHEDULE_GET_BY_CODE = '/api/schedule/get/CSchedule/';
    export const APP_UPDATE_ACTIVE_CSCHEDULE = '/api/schedule/activeSchedule';
    export const APP_PRODUCTS_SCHEDULE_GET_BY_CODE = '/api/schedule/get/code/';
    export const  APP_PRODUCTS_DAYS_GET_ALL = '/api/days/getAll';
    export const APP_PRODUCT_SERVICE_COMPLEMENT_SUMMARY = '/api/service/complements/';
