import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth/auth';
import CORE from './core/index';
import REBYU from './rebyu/index';
import geolocation from './geolocation/geolocation';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CORE,
    REBYU,
    auth,
    geolocation
  }
})
