export default class Search {

    constructor(
        search,
        lat,
        lng,
        id_user
    ){
        this.search = search ? search : '',
        this.lat = lat ? lat : '',
        this.lng = lng ? lng : '',
        this.id_user = id_user ? id_user : ''
    }
}