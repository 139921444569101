<template>
   <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    persistent
    width="290px"
    >
    <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
            <slot></slot>
        </div>
    </template>
        <v-date-picker
        v-model="date"
        no-title
        scrollable
        landscape
        locale="es"
        header-color="blue darken-4"
        >
         <div v-if="has_close">
            <v-switch
                color="success"
                v-model="close_end"
                hide-details
                style="margin-top: 0px"
            ></v-switch>
        </div>
        <v-spacer></v-spacer>
            <v-btn text color="primary" @click="remove" > Eliminar </v-btn>
            <v-btn text color="primary" @click="menu = false" > Cancel </v-btn>
            <v-btn text color="primary"  @click="save(date, close_end)" > OK </v-btn>
        </v-date-picker>
</v-menu>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        prevdate: {
            type: String,
            default: ""
        },
        has_close: {
            type: Boolean,
            default: false
        },
        is_close_end: {
            type: [Boolean, Number],
            default: false
        }
    },
    data: function() { 
        return {
            menu: false,
            date: (new Date(this.prevdate? new Date(this.prevdate) : Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            close_end: this.is_close_end
        }
    },
    methods: {
        save: function(date, close) {
            this.menu = false;
            const dt =  moment.utc(new Date(this.prevdate)).format('yyyy-MM-DD');
            const is_update_date = dt == date ? false : true;
            this.$emit('date', {date: date, close_end: close, is_update_date: is_update_date});
        },
        remove: function() {
            this.menu = false;
            this.$emit('remove', '');
        }
    },
    watch: {
        prevdate: function() {
            if(this.prevdate != null) {
                this.date = (new Date(this.prevdate? new Date(this.prevdate) : Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            }
        },
        is_close_end: function() {
            if(this.is_close_end != null) {
                this. close_end = this.is_close_end;
            }
        }
    }
   

}
</script>

<style>

</style>