<template>
  <v-card>
     <v-modal-title v-on:close="$emit('close')">
       </v-modal-title>
     <v-card-text class="py-2" >
         <div v-if="!image" class="bg-gray-100 px-5 text-gray-500 font-sans rounded-md">
             Seleccione un imagen para continuar
         </div>
         <cropper
           ref="cropper"
            class="cropper mt-2"
            :src="image"
			:transitions="true"
			image-restriction="fit-area"
            :min-height="height"
            :min-width="width"
        >
        </cropper>
       <div class="mt-2 flex gap-5 justify-center items-center">
           <v-btn
            color="pink"
            dark
            fab
             @click="$refs.file.click()"
            >
            <input ref="file" type="file" class="hidden" accept="image/*" @change="uploadImage($event)" />
            <v-icon>mdi-cloud-upload</v-icon>
           </v-btn>
            <v-btn
            color="red"
            dark
            fab
            @click="removeFile"
            >
            <v-icon>mdi-delete</v-icon>
           </v-btn>
       </div>
     </v-card-text>
      <v-card-actions>
          
            <v-spacer></v-spacer>
            
            <button :disabled="isloading"
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
              @click="$emit('close')">Cancelar</button>
             <button @click="save" :disabled="isloading"
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
               >Guardar</button>
       </v-card-actions>
  </v-card>
</template>

<script>
import {Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import toastMixin from "@/mixins/vue/toastmixin";




export default {
    mixins: [toastMixin],
    props: {
        open: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 500
        },
        width: {
            type: Number,
            default: 500
        }
    },
    components: {
        Cropper
    },
    data: function() {
        return {
            isloading: false,
            image: '',
            file: null,
            imagen: ''
        }
	},
	methods: {
        save: function() {
            if(this.$refs.cropper.getResult().canvas) {
                this.file = this.$refs.cropper.getResult().canvas.toDataURL();

                if(this.file) {
                    this.$emit('save', this.file);
                }else {
                    this.failure('Seleccione una imagen');
                }
            }else {
                    this.failure('Seleccione una imagen');
                }
        },
        uploadImage(event) {
			// Reference to the DOM input element
			var input = event.target;
			// Ensure that you have a file before attempting to read it
			if (input.files && input.files[0]) {
				// create a new FileReader to read this image and convert to base64 format
				var reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
                
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
					// Read image as base64 and set to imageData
					this.image = e.target.result;
				};

				// Start the reader job - read file as a data url (base64 format)
				reader.readAsDataURL(input.files[0]);
            
			}
		},
        removeFile() {
              this.image = null;
             this.$refs.file.value = '';
       }
	},
    watch: {
        open: function() {
            if(this.open) {
                this.$refs.file.value = '';
                this.image = '';
                this.file = null;
            }
        }
    }

}
</script>

<style scoped>
.cropper {
	height: 600px;
	background: #DDD;
}
</style>