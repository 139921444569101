import modules from '../../security/modules/modules';

export default [
    {
      path:'/entity/new',
      name:'EntityNew',
      component: () => import('../../views/entity/create.vue'),
      meta: {title: 'Nueva Entidad - Rebyu', requireAuth: true, layout: 'admin'},
    },
    {
      path:'/entity/edit/:id',
      name:'EditarEdit',
      component: () => import('../../views/entity/update.vue'),
      meta: {title: 'Editar Entidad - Rebyu', requireAuth: true, layout: 'admin'},
      props: route => ({id: route.params.id})
    },
    {
      path:'/entity/admin/:id',
      name:'Entity',
      component: () => import('../../views/entity/show.vue'),
      meta: {title: 'Entidad - Rebyu', requireAuth: true, layout: 'admin',  acm: modules.ENTITYDETAIL.name },
      props: route => ({id: route.params.id})
    },
    {
      path:'/entity/products/admin/:id/:identity',
      name:'Productos',
      component: () => import('../../views/entity/category/index.vue'),
      meta: {title: 'Productos - Rebyu', requireAuth: true,layout: 'admin', acm: modules.MENU.name},
      props: route => ({id: route.params.id, identity: route.params.identity})
    },
    {
      path:'/entity/service/:id/:identity',
      name:'ServiceDetail',
      component: () => import('../../views/entity/services/detail/index.vue'),
      meta: {title: 'Detalle Servicio - Rebyu', requireAuth: true, layout: 'admin'},
      props: route => ({id: route.params.id, identity: route.params.identity})
    },
    {
      path:'/entity/menu/:id/:identity',
      name:'MenuDetail',
      component: () => import('../../views/entity/menu/detail/index.vue'),
      meta: {title: 'Detalle Menú - Rebyu', requireAuth: true, layout: 'admin'},
      props: route => ({id: route.params.id, identity: route.params.identity})
    },
]