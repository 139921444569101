<template>
    <v-autocomplete
        filled
        solo
        clearable
        v-model="model"
        @change="$emit('input', model)"
        :disabled="!values || values.length <= 0 || isdisabled"
        :items="values"
        no-data-text="No se encontraron elementos"
        placeholder="Seleccione un elemento"
        :item-text="vtext"
        :item-value="vvalue"
        hide-details
        :height="10"
        class="bg-gray-500"
        :background-color="!values || values.length <= 0 || isdisabled ? ( dark ? '#323546' : 'blue-grey lighten-5' ): dark ? '#F9FAFB' : 'white'"
        :item-color="dark ? 'white': ''"
        :dark="dark"
        >
        <template v-slot:item="data">
                <template v-if="(typeof data.item !== 'object')">
                    <v-list-item-content v-text="data.item[title]"></v-list-item-content>
                </template>
                <template v-else >
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item[title]"></v-list-item-title>
                        <!-- <v-list-item-subtitle v-html="data.item[subtitle]"></v-list-item-subtitle> -->
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
</template>

<script>
export default {
    props: {
        values: {
            type: Array,
            default: function() { return []}
        },
        vtext: {
            type: String,
            default: 'text'
        },
        vvalue: {
            type: String,
            default: 'id'
        },
        title: {
            type: String,
            default: 'label'
        },
        subtitle: {
            type: String,
            default: 'description'
        },
        vmodel: {
            type: String,
            default: ''
        },
        isdisabled: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            model: this.vmodel ?  this.vmodel : ''
        }
    },
    mounted() {
        if(!this.vmodel) {
            this.model = '';
        }
    },
    watch : {
        vmodel: function() {
            this.model = this.vmodel ?  this.vmodel : ''
        }
    }

}
</script>

<style scoped>
.v-input__slot {
    align-items: center;
    color: inherit;
    display: flex;
    margin-bottom: 0px !important;
    min-height: inherit;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
}
</style>