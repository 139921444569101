import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from './routes/home'
import login from './routes/session'
import layout from '../views/dashboard/Home.vue';
import entity from './routes/entity';
import payment from './routes/payment';
import shared from './routes/shared';


Vue.use(VueRouter)


const routes = [

  { 
    path: '/',
    name: 'Home', 
    redirect: { name: 'IndexHome' } ,
    component: layout
  },
  ...login,
  ...dashboard,
  ...payment,
  ...entity,
  ...shared,

  { path: "*", component: () => import('@/views/error/404.vue') }

]


function ObtenerToken()
{
    let session = Vue.prototype.$session.get('token');
  
    if (session == undefined || !session)
    {
      return false;
    }
    return true;
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  /*Implementación para el login y otras verificaciones */
   const requiredAuth  = to.matched.some(record => record.meta.requireAuth);
   if (requiredAuth && to.name !== 'login' && !ObtenerToken()){
      next({ name: 'IndexHome' })
    } 

  if(to.name === 'login' && ObtenerToken()){
    next({ name: 'IndexHome'})
  } 

  else{
    next();
  }   

});


const DEFAULT_TITLE = 'Rebyu';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  })
})


export default router