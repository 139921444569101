<template>
  <vue-dropzone
    ref="myVueDropzone"
    :includeStyling="false"
    :useCustomSlot="true"
    id="dropzone"
    :options="dropzoneOptions"
    @vdropzone-file-added="fileAdded"
    >
    <div class="dropzone-container">
            <div class="file-selector">
                <div class="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-full text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                 <label class="text-gray-400 text-lg font-thin">Selecciona y suelta el archívo aquí</label>
                </div>
                <div class="text-center">
                 <span class="text-blue-500 cursor-pointer">O explorar</span>
                </div>
            </div>
    </div>
    </vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import utilFile from '../utils/file/file';

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data: function() {
        return {
            tempAttachments: [],
            attachments: [],
            dropzoneOptions: {
                url: "/",
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                autoProcessQueue: false,
                includeStyling: false,
                previewsContainer: false,
                maxFiles: 1,
                maxFilesize: 2,
                dictDefaultMessage: "Selecciona y suelta el archívo aquí"
            },

        }
    },
    methods : {
        fileAdded: async function(file) {
            let dataURL = await utilFile.getBase64(file);
            let dimensions = await utilFile.getFileDimensions(dataURL);
            let fileData = {
                dataURL: dataURL,
                height: dimensions.height,
                width: dimensions.width,
                name: file.name,
                size: file.size,
                type: file.type,
            };
            this.$emit('uploded', fileData);

        },
    }

}
</script>

<style scoped>

.file-selector {
  padding: 30px;
  background-color: #f9f9f9;
  color: #4e5b69;
  z-index: -9;
  border-radius: 15px;
}
figure {
  margin: 0px;
}
.dropzone-container {
  display: inherit;
  flex-direction: column;
  border: 1px dashed  rgba(59, 130, 246, 1);;
  border-radius: 15px;
}


</style>