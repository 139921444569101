import ApiRebyu from '../../../config/ApiRebyu';
import { APP_REBYU_PROFILE_PICTURE_SAVE } from '../../../utils/apiurl';

export default  {

    /**
     * Función que se encarga de guardar los datos de la foto de perfil del usuario
     * @param {any} data Modelo de datos del perfil
     * @returns 
     */
    async save(data) {
        try {
            const url = APP_REBYU_PROFILE_PICTURE_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


}
