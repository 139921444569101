<template>
    <div class="grid gap-y-1">
        <div class="flex gap-2 items-center">
            <f-icon :icon="['fas', 'fire']" />
            <span class="text-sm tracking-wide font-sans">Popular en RebyÜ</span>
        </div>
        <div class="flex flex-wrap gap-2">
            <router-link :to="'/searcher?busqueda=' + suggest" v-for="(suggest, s) in suggestiontags" :key="s"
                class="">
                <div class="bg-gray-200 px-2 font-bold font-sans text-sm rounded-lg shadow-md text-black">
                    {{ suggest ? suggest : '' }}</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        suggestiontags: {
            type: Array,
            default: function() {return []}
        }
    }
}
</script>

<style>

</style>