
export default {
    HM_AUTH_SET_TOKEN: 'SET_TOKEN',
    HM_AUTH_SET_USER: 'SET_USER',
    HM_AUTH_SET_LOCAL_UUID: 'SET_LOCAL_UUID',
    HM_AUTH_SET_SECURITY: 'SET_SECURITY',
    HM_AUTH_SET_DESTROY: 'SET_DESTROY',
    HM_AUTH_SET_AUTH_EXCEPTION: 'SET_AUTH_EXCEPTION',
    HM_AUTH_SET_AUTH_UNAUTHORIZED: 'SET_AUTH_UNAUTHORIZED',



    //REBYU
    RB_ENTITY_SET_ENTITIES: 'RB_ENTITY_SET_ENTITIES',
    RB_ENTITY_SET_ENTITY: 'RB_ENTITY_SET_ENTITY',
}