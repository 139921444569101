export default [
    {
      path:'/login',
      name:'login',
      component: () => import('../../views/session/login.vue'),
      meta: {title: 'Login - Rebyu', layout: 'default' },
    },
    {
      path:'/auth/register',
      name:'register',
      component: () => import('../../views/session/register.vue'),
      meta: {title: 'Register - Rebyu', layout: 'default'},
      props: route => ({id_role: route.query.x})
    },
    {
      path:'/auth/register/partners',
      name:'partners',
      component: () => import('../../views/session/registerpartners.vue'),
      meta: {title: 'Socios - Rebyu', layout: 'default'},
      props: route => ({id_role: route.query.x})
    },
    {
      path:'/auth/email-verifiy/:param',
      name:'checkMail',
      component: () => import('../../views/session/checkMail.vue'),
      meta: {title: 'Confirmation - Rebyu', layout: 'default'},
    }        
]