<template>
<div id="mapDiv">
   <GmapMap
    ref="vgmaps"
    :center="center"
    :zoom="gzoom"
    :style="mstyle"
    @click="addMarker"
    >
    <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
        :clickable="isclickable"
        :draggable="isdraggable"
        @dragend="addMarker"
      />
   </GmapMap>
</div>
</template>

<script>
export default {
    props: {
        mstyle: {
            type: String,
            default: "width: 100%; height: 300px"
        },
        vzoom: {
            type: Number,
            default:12
        },
        vmarkers: {
            type: Object,
            default: function() {return {}}
        },
        isclickable: {
            type: Boolean,
            default: true
        },
        isdraggable: {
            type: Boolean,
            default: true
        }
    },
    data: () => {
        return {
               center: {lat: 0, lng: 0},
               markers: [],
               places: [],
               currentPlace: null,
               gzoom_value: 12
        }
    },
    mounted() {
        if(!this.vmarkers || !this.vmarkers.lat || !this.vmarkers.lng) {
            this.gzoom = this.vzoom;
            this.geolocate();
        }else {
            this.gzoom = 15;
            const marker = {
                lat: this.vmarkers.lat,
                lng: this.vmarkers.lng,
            };
            this.center = marker;
            this.markers = [];
            this.markers.push({ position: marker });
            this.$refs.vgmaps.panTo(marker);
        }
    },
    methods: {
       geolocate: function() {
        navigator.geolocation.getCurrentPosition(position => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        });
        },
        addMarker(event) {
            if(this.isclickable) {
                this.currentPlace = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                };
                if (this.currentPlace) {
                    const marker = {
                        lat: this.currentPlace.lat,
                        lng: this.currentPlace.lng,
                    };
                    this.markers = [];
                    this.markers.push({ position: marker });
                    this.$refs.vgmaps.panTo(marker);
                    this.$emit('marker', marker);
                }
            }
        },
    },
    computed: {
        gzoom:  {
            get: function() {
                return this.gzoom_value;
            },
            set: function(value) {
                this.gzoom_value = value;
            }
        }
    },
    watch: {
        vmarkers: function() {

             if(this.vmarkers && this.vmarkers.lat && this.vmarkers.lng) {
                this.gzoom = 15;
                const marker = {
                    lat: this.vmarkers.lat,
                    lng: this.vmarkers.lng,
                };
                this.center = marker;
                this.markers = [];
                this.markers.push({ position: marker });
                this.$refs.vgmaps.panTo(marker);
            }
        }
    }
}
</script>

<style>

</style>