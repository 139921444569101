<template>
  <div class="h-full w-full">
      <slot> </slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>