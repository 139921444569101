import ToastificationContent from '@/@core/components/toastification/toastcontent.vue'

export default {
    data: function() {
        return {
            primary: 'green-500',
            warningbg: 'yellow-500',
            error: 'red-500',
        }
    },
    methods : {
        /**
         * Función que permite mostrar la alerta success
         * @param {string} title Titulo del texto
         * @param {string} text Texto del toat
         * @param {string} variant variante
         * @param {string} icon icono
         */
        success : function(text = '',title = 'Operación correcta', time = 2000, variant = this.primary, icon = 'check') {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: time,
                props: {
                    title: title,
                    text: text,
                    variant: variant,
                    icon: icon,
                }
            });
        },
        /**
        * Función que permite mostrar la alerta Error
        * @param {string} title Titulo del texto
        * @param {string} text Texto del toat
        * @param {string} variant variante
        * @param {string} icon icono
        */
        failure : function(text = '',title = 'Error en la operación', time = 1500, variant = this.error, icon = 'times-circle' ) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: time,
                props: {
                    title: title,
                    text: text,
                    variant: variant,
                    icon: icon,
                }
            });
        },
        /**
        * Función que permite mostrar la alerta Error
        * @param {string} title Titulo del texto
        * @param {string} text Texto del toat
        * @param {string} variant variante
        * @param {string} icon icono
        */
         warning : function(text = '',title = 'Alerta en la operación', time = 1500, variant = this.warningbg, icon = 'exclamation-circle' ) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: time,
                props: {
                    title: title,
                    text: text,
                    variant: variant,
                    icon: icon,
                }
            });
        }
    }
}