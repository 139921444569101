import permissions from '../permissions/permissions';

const modules = 
    {
        PROFILE: {el: 1, permissions: {...permissions.PROFILE_PERMISSIONS}, name: 'PROFILE' },
        HOME: { el: 2, permissions: {...permissions.HOME_PERMISSIONS}, name: 'HOME' },
        ENTITYDETAIL: { el: 4, permissions: {...permissions.ENTITY_DETAIL_PERMISSIONS}, name: 'ENTITYDETAIL' },
        ENTITYINFO: { el: 5, permissions: {...permissions.ENTITY_DETAIL_INFORMATION}, name: 'ENTITYINFO' },
        ENTITYADDRESS: { el: 6, permissions: {...permissions.ENTITY_DETAIL_ADDRESS}, name: 'ENTITYADDRESS' },
        MENU: { el: 11, permissions: {...permissions.MENU}, name: 'MENU' },
    }


export default {
    ...modules
}