<template>
   <v-card-title class="flex justify-between  items-center bg-gray-100 border border-gray-100">
          <span class="text-xl text-gray-600"><slot></slot></span>
           <f-icon class="font-normat text-lg text-gray-400 hover:text-gray-500 cursor-pointer" :icon="['fas', 'times']"  @click="$emit('close')" />
    </v-card-title>
</template>

<script>
export default {

}
</script>

<style>

</style>