<template>
    <div>
        <v-menu
            transition="slide-y-transition"
            bottom
            v-model="menu"
            >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" >
                    <slot name="button"></slot>
                </div>
            </template>
             <v-card
                class="mx-auto"
                max-width="300"
                tile >
                    <v-list :rounded="true" v-if="actions && actions.length > 0">
                        <v-list-item-group >
                            
                        <v-list-item  v-for="item in actions" v-bind:key="item.action" @click="onEvent(item.action)" class="text-gray-700" v-security="{acm: item.acm, acc: item.acc}" >
                            <v-list-item-icon >
                                <f-icon :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}" :icon="['fas', item.icon]"></f-icon>
                            </v-list-item-icon>
                          
                            <v-list-item-content>
                            <v-list-item-title :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}">{{item.label ? item.label : ''}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                    </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        actions: {
            type: Array,
            default: function() {return []}
        },
    },
    computed: {
        menu: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onEvent(event) {
            this.menu = false;
            this.$emit('event', event);
        }
    },
   
}
</script>

<style>

</style>