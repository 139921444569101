<template>
  <div class="toastification">
      <div class="flex items-start">
          <div class="rounded-full px-2 py-1 text-white" :class="`bg-${variant}`">
              <f-icon v-if="icon" :icon="['fas', icon]" />
          </div>
          <div class="flex flex-grow items-center">
              <div>
                  <h5 v-if="title"
                   v-text="title"
                   class="mb-0 px-2 text-base font-sans font-medium tracking-wide toastification-title"
                   :class="`text-${variant}`"
                  />
                   <small
                    v-if="text"
                    class="text-sm inline-block text-gray-500 px-2"
                    v-text="text"
                    />
              </div>
              <span
                class="absolute right-3 top-1 cursor-pointer toastification-close-icon ml-auto "
                @click="$emit('close-toast')"
                >
                <f-icon
                    v-if="!hideClose"
                    :icon="['fas', 'times']"
                    class=" text-gray-300"
                />
                </span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'green-500',
        },

        icon: {
             type: String,
             default: null,
        },

        title: {
        type: String,
        default: null,
        },

        text: {
        type: String,
        default: null,
        },

      hideClose: {
        type: Boolean,
        default: false,
    },
    }
}
</script>