import Vue from 'vue';

export default  {
    namespaced: true,
    state: {
        currentlocation: Vue.prototype.$session ? Vue.prototype.$session.get('cl'): '',
    },

    mutations: {
        SET_CURRENT_LOCATION (state, location)  {
            state.location = location;
            if(location) {              
                Vue.prototype.$session.set('cl', location)
            }else {
                state.location = null;
            }
        },
    },

    actions: {

        async setCurrentLocation({dispatch}, data) {
            dispatch('setlocation', JSON.stringify(data));
        },

        async setlocation({commit},  location) {
           
            if(location) {
                commit('SET_CURRENT_LOCATION', location);
            }
        },
    },

    getters: {
        currentlocation (state) {
            let location = Vue.prototype.$session.get('cl');
            if(location) {
                state.currentlocation = JSON.parse(location);
            }
            return state.currentlocation ? state.currentlocation : '';
        }
    }


}