<template>
    <div>
     <button @click="sheet = !sheet"  type="button" class="focus:outline-none flex md:hidden w-full mx-auto py-2 px-3 rounded-lg justify-center text-gray-500">
       <slot></slot>
     </button>
      <v-bottom-sheet v-model="sheet">
        <v-sheet  class="text-center mt-2xl">
         <div class="flex justify-between items-center px-5 py-2 text-black rounded-tl-2xl rounded-tr-2xl">
                <label class="font-sans tracking-wider text-lg font-bold">Opciones</label>
                <div>
                    <svg @click="sheet = !sheet" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
          </div>
        <div class="py-5 grid gap-y-5 px-1">
            <div class="flex justify-between items-center px-5 py-1 "  v-for="item in actions" v-bind:key="item.action" @click="onEvent(item.action)" v-security="{acm: item.acm, acc: item.acc}">
                <div class="flex gap-5 items-center">
                    <div>
                        <f-icon class="text-lg" :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}" :icon="['fas', item.icon]"></f-icon>
                    </div>
                <div class="grid text-left">
                    <label :class="item.action == 'ON.DELETE' ? 'text-red-500' : 'text-black '" class="font-sans text-lg font-normal tracking-wider">{{item.label ? item.label : ''}}</label>
                     <small class="text-gray-400 tracking-wide font-sans">{{item.description ? item.description : ''}}</small>
                </div>
                </div>
            </div>
        </div>
        </v-sheet>
    </v-bottom-sheet>
  </div>

</template>

<script>
export default {
    props: {
        actions: {
            type: Array,
            default: function() {return []}
        },
    },
    computed: {
        sheet: {
            get () {
                return this.$attrs.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onEvent(event) {
            this.sheet = false;
            this.$emit('event', event);
        }
    },
}
</script>

<style scoped>

.mt-2xl {
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
}
</style>