<template>
  <div @click="back()" class="group bg-gray-200 shadow-sm px-2 py-1 rounded-lg cursor-pointer hover:bg-fucsia-lighter hover:shadow-md">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 text-gray-500 group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="text-sm tracking-wider font-medium text-gray-500 group-hover:text-white">Atras</span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        back() {
            this.$router.go(-1);
        }
    }
}
</script>

<style>

</style>