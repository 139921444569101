import Vue from 'vue';
import modules_p from '../security/modules/modules';
import store from "../store/index";

Vue.directive('security', {
    bind: function(el, binding, vnode) {
        // VALIDAR QUE EXISTAN LOS PARAMETROS
        if(!modules_p[binding.value.acm]) return vnode.elm.style.display = 'none';
        if(!store.getters['auth/security'][modules_p[binding.value.acm].el])  return vnode.elm.style.display = 'none' ;
        if(!modules_p[binding.value.acm].permissions[binding.value.acc])  return vnode.elm.style.display = 'none' ;

        // OBTENER LOS VALORES DE LOS PARAMETROS
        const security = store.getters['auth/security'][modules_p[binding.value.acm].el];
        const request = modules_p[binding.value.acm].permissions[binding.value.acc];
        if(!((security & request) == request ) ) {
            vnode.elm.style.display = 'none';
        }else {
         vnode.elm.style.display = '';
        }
    },
    componentUpdated(el, binding, vnode) {
         // VALIDAR QUE EXISTAN LOS PARAMETROS
        if(!modules_p[binding.value.acm]) return vnode.elm.style.display = 'none';
        if(!store.getters['auth/security'][modules_p[binding.value.acm].el]) return vnode.elm.style.display = 'none';
        if(!modules_p[binding.value.acm].permissions[binding.value.acc])  return vnode.elm.style.display = 'none' ;

        // OBTENER LOS VALORES DE LOS PARAMETROS
        const security = store.getters['auth/security'][modules_p[binding.value.acm].el];
        const request = modules_p[binding.value.acm].permissions[binding.value.acc];
        if(!((security & request) == request ) ) {
            vnode.elm.style.display = 'none';
        }else {
         vnode.elm.style.display = '';
        }
    }
});