<template>
    <div class="flex justify-center items-center animate-fade-in-up gap-1">
        <div :class="classes" class="w-full h-32  bg-opacity-50 rounded-lg">
            <slot name="input"></slot>
        </div>
    </div>
</template>

<script>
import searcheable from '../../@core/mixins/searcheable';

export default {
    mixins: [searcheable],
    computed: {
       classes() {
            return {...this.searcheableClass}
       }
    }
}
</script>

<style scoped>
    .back-drop-2 {
        backdrop-filter: blur(2px);
    }
</style>