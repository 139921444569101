<template>
  <rb-search-bar-content :home="home" :search="search">
    <template #input>
      <v-search-input :suggestions="suggestiontags" :home="home" :search="search" />
      <slot name="suggest"></slot>
    </template>
  </rb-search-bar-content>
</template>

<script>
import ToastMixin from "../mixins/vue/toastmixin.js";
import searchEngineService from "../services/searchengine/searchengine.service";
import RBSearchBarContent from "./searchbar/RBSearchBarContent.vue";
import searcheable from "../@core/mixins/searcheable";

export default {
  mixins: [ToastMixin, searcheable],
  props: {
    hassuggest: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      searchsuggestiontags: [],
    };
  },
  components: {
    "rb-search-bar-content": RBSearchBarContent,
  },
  mounted() {
    this.init();
  },
  methods: {
    init: async function() {
      await this.getSuggestionsTags();
    },
    getSuggestionsTags: async function(LIMIT = 5) {
      let response = await searchEngineService.getSuggestionTags(LIMIT);
      if (response.success) {
        this.searchsuggestiontags = response.data;
      }
    },
  },
  computed: {
    suggestiontags: function() {
      return [
        ...new Set(this.searchsuggestiontags.map((item) => item.description)),
      ];
    },
  },
};
</script>

<style></style>
